import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Button, Divider, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {numberWithCommasToman, Page, toPersianDateTime} from "../../../../services/UtilFunctions";
import {CardNumberEdit} from "../../../common/CardNumberEdit";
import UserService, {User} from "../../../../services/UserService";
import WithdrawRequestService, {WithdrawRequestOutputModel} from "../../../../services/WithdrawRequestService";
import {WithdrawStatusTitle} from "../../../../constans/enums";
import {WithdrawRequestDrawer} from "./WithdrawRequestDrawer";

export const WithdrawRequestContainer: React.FC = () => {

    const [data, setData] = useState<Page<WithdrawRequestOutputModel>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });
    const [isSendRequestOpen, setIsSendRequestOpen] = useState<boolean>(false);
    const [user, setUser] = useState<User>();


    useEffect(() => {
        UserService.getSelfProfile().then(value => setUser(value));
        loadData();
    }, [params]);

    const loadData = () => {
        setLoading(true);
        WithdrawRequestService.getSelfWithdrawRequestPage(params).then(page => {
            setData(page);
        }).finally(() => setLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<WithdrawRequestOutputModel> = [
        {
            title: 'شناسه درخواست',
            dataIndex: 'id',
            width: 150,
        },
        {
            title: 'تاریخ درخواست',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, {createdDate}) => (
                <>{createdDate && toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'مبلغ',
            dataIndex: 'amount',
            width: 200,
            render: (_, {amount}) => (
                <>{numberWithCommasToman(amount)}</>
            ),
        },
        {
            title: 'وضعیت پرداخت',
            dataIndex: 'status',
            width: 150,
            render: (_, record) => (
                <>{WithdrawStatusTitle[record.status]}</>
            ),
        },
        {
            title: 'شماره کارت',
            dataIndex: 'cardNumber',
            width: 200,
        },
        {
            title: 'کد رهگیری',
            dataIndex: 'trackId',
            width: 300,
        },
        {
            title: 'پیام',
            dataIndex: 'adminComment',
            width: 300,
        }
    ];

    return (
        <>
            <div style={{marginRight: "15px", marginBottom: "15px"}}>
                <Space>
                    <CardNumberEdit cardNumber={user?.cardNumber} callBack={() => {
                        UserService.getSelfProfile().then(value => setUser(value));
                    }}/>
                </Space>
            </div>

            <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
                <div style={{
                    color: SECOND_COLOR,
                    fontSize: "18px",
                    fontWeight: 'bold',
                    marginBottom: '10px'
                }}>درخواست برداشت وجه
                </div>
                <Divider/>

                <Button type="primary" onClick={() => {
                    setIsSendRequestOpen(true);
                }}>
                    ارسال درخواست
                </Button>

                <WithdrawRequestDrawer
                    cardNumber={user?.cardNumber}
                    isOpen={isSendRequestOpen}
                    onClose={() => {
                        setIsSendRequestOpen(false);
                    }}
                    loadData={loadData}
                />

                <Table rowKey="id" scroll={{x: 1200}} loading={loading} pagination={{
                    position: ['bottomCenter'],
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: params.pageSize,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"
                />
            </Wrapper1>
        </>
    );
}