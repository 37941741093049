import React, {useEffect, useState} from "react";
import {Button, Form, Input, Modal, Select} from "antd";
import {openSuccessNotification} from "../../../../services/UtilFunctions";
import NotificationService from "../../../../services/NotificationService";
import TextArea from "antd/es/input/TextArea";

const {Option} = Select;

interface Props {
    show: boolean

    setShow(b: boolean): void
}

export const NotificationCreate = ({show, setShow}: Props) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        setLoading(true);
        NotificationService.add(values).then(response => {
            form.resetFields();
            setShow(false);
            setLoading(false);
            openSuccessNotification();
        }).catch(reason => {
            setLoading(false);
        });
    }

    useEffect(() => {
    }, []);

    return (
        <Modal
            title="افزودن اعلان جدید"
            style={{marginTop:'10px', marginBottom:'10px'}}
            centered
            open={show}
            onOk={() => {
            }}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
            width={800}
        >
            <Form
                form={form}
                name="basic"
                wrapperCol={{span: 16}}
                style={{maxWidth: 800}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
            >
                <Form.Item
                    name="title"
                    label="عنوان"
                >
                    <Input/>
                </Form.Item>
                <Form.Item name="description" label="توضیحات"><TextArea rows={4}/></Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        افزودن
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}