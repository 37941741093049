import {Carousel} from 'antd';
import React from "react";
import daneshgahino from '../../assets/images/daneshgahino.webp';
import affiliateSlider from '../../assets/images/affiliate-slider.webp';
import {useNavigate} from "react-router-dom";
import {LANDING_AFFILIATE_ROUTE} from "../../routes/route-path";

export const MainSlider = () => {

    const navigate = useNavigate();

    return (
        <div className="ltr" style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingRight: '0px',
            paddingLeft: '0px',
            marginTop: '35px'
        }}>
            <Carousel effect="scrollx" swipeToSlide draggable autoplay>
                {/*<div>*/}
                {/*    <img src={banner2} style={{width:'100%'}}/>*/}
                {/*</div>*/}
                {/*<div style={{cursor:'pointer'}} onClick={() => navigate(LANDING_AFFILIATE_ROUTE)}>*/}
                {/*    <img src={affiliateSlider} style={{width:'100%'}}/>*/}
                {/*</div>*/}
                <a href={LANDING_AFFILIATE_ROUTE}>
                    <img src={affiliateSlider} style={{width: '100%'}}/>
                </a>
                <div>
                    <img src={daneshgahino} style={{width:'100%'}}/>
                </div>
            </Carousel>

        </div>
    );

}