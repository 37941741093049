import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {ConfigProvider, Divider, Space, Spin, Table} from "antd";
import UserPlanService, {
    PlanPriceReport,
    PlanReport,
    UserPlanTotalReportModel
} from '../../../../services/UserPlanService'
import {getLocalDateTime, numberWithCommasToman} from "../../../../services/UtilFunctions";
import {ColumnsType} from "antd/es/table";
import {DatePicker, JalaliLocaleListener} from 'antd-jalali';
import faIR from 'antd/locale/fa_IR';
import {LoadingOutlined} from "@ant-design/icons";

const {RangePicker} = DatePicker;

interface QueryParams {
    from?: string;
    to?: string;
}

export const UserPlanTotalReport: React.FC = () => {

    const [data, setData] = useState<UserPlanTotalReportModel>();
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState<QueryParams>({});

    useEffect(() => {
        if (params.from && params.to) {
            load();
        }
    }, [params]);

    const load = () => {
        setLoading(true);
        UserPlanService.getTotalReportForAdmin(params).then(data => {
            setData(data)
        }).finally(() => setLoading(false));
    }

    const columns: ColumnsType<PlanReport> = [
        {
            title: "اشتراک",
            dataIndex: "planTitle",
            key: "planTitle",
        },
        {
            title: "مجموع فروش",
            dataIndex: "totalPrice",
            key: "totalPrice",
            render: value => numberWithCommasToman(value)
        },
        {
            title: "مجموع سهم معرف",
            dataIndex: "refAffiliateReward",
            key: "refAffiliateReward",
            render: value => numberWithCommasToman(value)
        },
        {
            title: "تعداد",
            dataIndex: "count",
            key: "count",
        },
    ];

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>گزارش اشتراک های خریداری شده</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Space className="ltr" style={{marginTop: "20px"}}>
                <ConfigProvider
                    locale={faIR}
                    direction='rtl'
                >
                    <JalaliLocaleListener/>
                    <RangePicker showTime onChange={(dates: any) => {
                        if (dates) {
                            const from = new Date(dates[0] as string)
                            const to = new Date(dates[1] as string)
                            setParams({
                                from: getLocalDateTime(from),
                                to: getLocalDateTime(to),
                            })
                        } else {
                            setParams({})
                        }
                    }}/>
                </ConfigProvider>

            </Space>
            <>
                {
                    loading ? <div
                            style={{
                                height: '300px',
                                display: loading ? 'flex' : 'none',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Spin indicator={<LoadingOutlined style={{fontSize: 48}} spin/>}/>
                        </div> :
                        (data &&
                            <>
                                <div style={{margin: "20px 0px", fontSize: '16px'}}>
                                    <b>مجموع فروش:</b> {numberWithCommasToman(data.totalPrice)} <br/>
                                    <b>مجموع سهم معرف:</b> {numberWithCommasToman(data.refAffiliateReward)} <br/>
                                    <b>تعداد:</b> {data.count} <br/>
                                </div>

                                <Table
                                    className="admin-course-table"
                                    columns={columns}
                                    dataSource={data.planReports}
                                    expandable={{
                                        expandedRowRender: (record) => (
                                            <PlanPriceReportsTable planPriceReports={record.planPriceReports}/>
                                        ),
                                    }}
                                    pagination={false}
                                    rowKey="planId"
                                />
                            </>)
                }
            </>
        </Wrapper1>
    )
        ;
}

// Child Table Component (PlanPriceReports)
interface PlanPriceReportsTableProps {
    planPriceReports: PlanPriceReport[]
}

const PlanPriceReportsTable = ({planPriceReports}: PlanPriceReportsTableProps) => {
    const columns: ColumnsType<PlanPriceReport> = [
        {
            title: "با قیمت",
            dataIndex: "finalPrice",
            key: "finalPrice",
            render: value => numberWithCommasToman(value)
        },
        {
            title: "مجموع فروش",
            dataIndex: "totalPrice",
            key: "totalPrice",
            render: value => numberWithCommasToman(value)
        },
        {
            title: "مجموع سهم معرف",
            dataIndex: "refAffiliateReward",
            key: "refAffiliateReward",
            render: value => numberWithCommasToman(value)
        },
        {
            title: "تعداد",
            dataIndex: "count",
            key: "count",
        },
    ];

    return <Table className="admin-course-table" columns={columns} dataSource={planPriceReports} pagination={false}
                  rowKey="finalPrice"/>;
};