import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, Modal, Select} from "antd";
import {openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";
import PlanService, {Plan} from "../../../../services/PlanService";
import DiscountCodeService from "../../../../services/DiscountCodeService";
import UserService, {User} from "../../../../services/UserService";

const {Option} = Select;

interface Props {
    show: boolean

    setShow(b: boolean): void
}

export const DiscountCodeCreate = ({show, setShow}: Props) => {

    const [form] = Form.useForm()
    const [plans, setPlans] = useState<Plan[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        if (values.planIds && values.planIds.length === 0) {
            values = {...values, planIds: null}
        }
        setLoading(true);
        DiscountCodeService.create(values).then(response => {
            form.resetFields();
            setShow(false);
            setLoading(false);
            openSuccessNotificationByMessage('کد تخفیف: ' + response.code + ' ساخته شد.', 0);
        }).catch(reason => {
            setLoading(false);
        });
    }

    useEffect(() => {
        PlanService.getPlansForAdmin({pageNumber: 0, pageSize: 1000}).then(page => {
            setPlans(page.content)
        });
        UserService.getUsers({pageNumber: 0, pageSize: 10000}).then(page => {
            setUsers(page.content)
        });
    }, []);

    const justAllowNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, e.target.value.replace(/\D/g, ''))
    };

    return (
        <Modal
            title="افزودن کد تخفیف جدید"
            style={{marginTop:'10px', marginBottom:'10px'}}
            centered
            open={show}
            onOk={() => {
            }}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
            width={800}
        >
            <Form
                form={form}
                name="basic"
                wrapperCol={{span: 16}}
                style={{maxWidth: 800}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
            >
                <Form.Item
                    name="title"
                    label="عنوان"
                >
                    <Input placeholder="عنوان"/>
                </Form.Item>
                <Form.Item
                    name="code"
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                    label="کد تخفیف"
                >
                    <Input placeholder="کد تخفیف"/>
                </Form.Item>
                <Form.Item name="planIds" label="اشتراک">
                    <Select
                        placeholder="اشتراک"
                        allowClear={true}
                        mode="multiple"
                    >
                        {
                            plans.map(plan => {
                                return (<Option value={plan.id}>{plan.title}</Option>)
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item name="userId" label="کاربر">
                    <Select
                        placeholder="کاربر"
                        allowClear={true}
                    >
                        {
                            users.map(user => {
                                return (<Option value={user.id}>{user.username}</Option>)
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="discountPercentage"
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                    label="درصد تخفیف"
                >
                    <InputNumber style={{width: "100%"}} min={0} max={100} placeholder="درصد تخفیف"/>
                </Form.Item>
                <Form.Item
                    name="days"
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                    label="تعداد روز"
                >
                    <Input name="days" onChange={justAllowNumbers} placeholder="تعداد روز"/>
                </Form.Item>

                <Form.Item
                    name="maxUsedCount"
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                    label="حداکثر تعداد استفاده"
                >
                    <Input name="maxUsedCount" onChange={justAllowNumbers} placeholder="حداکثر تعداد استفاده"/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        افزودن
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}