import React from "react";
import {Card, Row, Typography} from "antd";
import {ImageSliderAffiliate} from "./ImageSliderAffiliate";
const importImage = (filePath : string) => require(`../../assets/images/affiliate/${filePath}`);

const {Title} = Typography;

const images = [
    {
        category: "ریاضی",
        titleColor:'rgb(154,9,188)',
        type: "تصاویر استوری",
        zipPath:'mathematics/story/mathematics-story.zip',
        files:[{orgPath:"mathematics/story/1.jpg", previewPath:"mathematics/story/1.webp"}, {orgPath:"mathematics/story/2.jpg", previewPath:"mathematics/story/2.webp"}, {orgPath:"mathematics/story/3.jpg", previewPath:"mathematics/story/3.webp"}, {orgPath:"mathematics/story/4.jpg", previewPath:"mathematics/story/4.webp"}]
    },
    {
        category: "ریاضی",
        titleColor:'rgb(154,9,188)',
        type: "تصاویر پست",
        zipPath:'mathematics/post/mathematics-post.zip',
        files: [{orgPath:"mathematics/post/1.jpg", previewPath:"mathematics/post/1.webp"}, {orgPath:"mathematics/post/2.jpg", previewPath:"mathematics/post/2.webp"}, {orgPath:"mathematics/post/3.jpg", previewPath:"mathematics/post/3.webp"}, {orgPath:"mathematics/post/4.jpg", previewPath:"mathematics/post/4.webp"}]
    },
    {
        category: "علوم کامپیوتر",
        titleColor:'#424242',
        type: "تصاویر استوری",
        zipPath:'computerscience/story/computerscience-story.zip',
        files:[{orgPath:"computerscience/story/1.jpg", previewPath:"computerscience/story/1.webp"}, {orgPath:"computerscience/story/2.jpg", previewPath:"computerscience/story/2.webp"}, {orgPath:"computerscience/story/3.jpg", previewPath:"computerscience/story/3.webp"}, {orgPath:"computerscience/story/4.jpg", previewPath:"computerscience/story/4.webp"}]
    },
    {
        category: "علوم کامپیوتر",
        titleColor:'#424242',
        type: "تصاویر پست",
        zipPath:'computerscience/post/computerscience-post.zip',
        files:[{orgPath:"computerscience/post/1.jpg", previewPath:"computerscience/post/1.webp"}, {orgPath:"computerscience/post/2.jpg", previewPath:"computerscience/post/2.webp"}, {orgPath:"computerscience/post/3.jpg", previewPath:"computerscience/post/3.webp"}, {orgPath:"computerscience/post/4.jpg", previewPath:"computerscience/post/4.webp"}]
    },
    {
        category: "آمار",
        titleColor:'#ff8237',
        type: "تصاویر استوری",
        zipPath:'statistics/story/statistics-story.zip',
        files:[{orgPath:"statistics/story/1.jpg", previewPath:"statistics/story/1.webp"}, {orgPath:"statistics/story/2.jpg", previewPath:"statistics/story/2.webp"}, {orgPath:"statistics/story/3.jpg", previewPath:"statistics/story/3.webp"}, {orgPath:"statistics/story/4.jpg", previewPath:"statistics/story/4.webp"}]
    },
    {
        category: "آمار",
        titleColor:'#ff8237',
        type: "تصاویر پست",
        zipPath:'statistics/post/statistics-post.zip',
        files:[{orgPath:"statistics/post/1.jpg", previewPath:"statistics/post/1.webp"}, {orgPath:"statistics/post/2.jpg", previewPath:"statistics/post/2.webp"}, {orgPath:"statistics/post/3.jpg", previewPath:"statistics/post/3.webp"}, {orgPath:"statistics/post/4.jpg", previewPath:"statistics/post/4.webp"}]
    }
];

export const ImageSliderAffiliateContainer = () => {

    return (
        <>
            {images.map((section, index) => (
                <Card key={index} title={<Title level={5} style={{color:section.titleColor}}>{`${section.category} - ${section.type}  `}
                    <a style={{marginRight:'10px'}} href={importImage(section.zipPath)} download>دانلود</a></Title>}
                      style={{marginBottom: "20px"}}>
                    <Row gutter={[16, 16]} justify="center">
                        <ImageSliderAffiliate files={section.files}/>
                    </Row>
                </Card>
            ))}
        </>
    );

}