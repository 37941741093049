import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Button, Divider, Modal, Space, Table, TableProps} from "antd";
import {ColumnsType} from "antd/es/table";
import {openSuccessNotificationByMessage, Page, toPersianDateTime} from "../../../../services/UtilFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {DiscountCodeCreate} from "./DiscountCodeCreate";
import DiscountCodeService, {DiscountCode} from "../../../../services/DiscountCodeService";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {DiscountCodeExcelCreate} from "./DiscountCodeExcelCreate";

export const DiscountCodeContainer: React.FC = () => {

    const [data, setData] = useState<Page<DiscountCode>>();
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showExcelCreateModal, setShowExcelCreateModal] = useState<boolean>(false);
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });
    const [deleteModal, deleteModalContextHolder] = Modal.useModal();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(false);

    useEffect(() => {
        loadDiscountCodes();
    }, [params, showCreateModal, showExcelCreateModal]);

    const loadDiscountCodes = () => {
        setDataLoading(true);
        DiscountCodeService.getPage(params).then(page => {
            page.content = page.content.map(value => {
                return {
                    ...value,
                    key: value.id
                }
            });
            setData(page)
        }).finally(() => setDataLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<DiscountCode> = [
        {
            title: 'کد تخفیف',
            dataIndex: 'code',
            width: 100
        },
        {
            title: 'اشتراک ها',
            dataIndex: 'plans',
            width: 100
        },
        {
            title: 'تاریخ انقضا',
            dataIndex: 'endDate',
            width: 100,
            render: (_, {endDate}) => (
                <>{toPersianDateTime(endDate)}</>
            ),
        },
        {
            title: 'درصد تخفیف',
            dataIndex: 'discountPercentage',
            width: 70
        },
        {
            title: 'حداکثر تعداد استفاده',
            dataIndex: 'maxUsedCount',
            width: 70,
        },
        {
            title: 'تعداد استفاده تا کنون',
            dataIndex: 'usedUsers',
            width: 70,
            render: (_, {usedUsers}) => (
                <>{
                    usedUsers ? usedUsers.length : 0
                }</>
            ),
        },
        {
            dataIndex: 'id',
            width: 70,
            render: (_, discountCode) => (
                <>
                    <a onClick={() => {
                        deleteModal.confirm({
                            title: 'آیا می خواهید این کد تخفیف را حذف کنید؟',
                            icon: <ExclamationCircleOutlined/>,
                            okText: 'بله',
                            cancelText: 'انصراف',
                            okButtonProps: {danger: true},
                            onOk: () => {
                                DiscountCodeService.delete(discountCode.id).then(() => {
                                    openSuccessNotificationByMessage("کد تخفیف با موفقیت حذف شد.");
                                    loadDiscountCodes();
                                });
                            }
                        })
                    }}>حذف</a>
                </>
            )
        }
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableProps<DiscountCode>['rowSelection'] = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>مدیریت کد‌های تخفیف</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Space style={{flexWrap: "wrap"}}>
                <Button style={{marginTop: "20px"}} type="primary" onClick={() => setShowCreateModal(true)}>
                    <span style={{marginLeft: "5px"}}><FontAwesomeIcon icon={faPlus}/></span>
                    <span>افزودن کد تخفیف جدید</span>
                </Button>
                <Button style={{marginTop: "20px"}} type="primary" onClick={() => setShowExcelCreateModal(true)}>
                    <span style={{marginLeft: "5px"}}><FontAwesomeIcon icon={faPlus}/></span>
                    <span>افزودن کد تخفیف جدید(excel)</span>
                </Button>
                <Button disabled={!hasSelected} style={{marginTop: "20px"}} type="primary" danger onClick={() => {
                    deleteModal.confirm({
                        title: 'آیا می خواهید این کدهای تخفیف  را حذف کنید؟',
                        icon: <ExclamationCircleOutlined/>,
                        okText: 'بله',
                        cancelText: 'انصراف',
                        okButtonProps: {danger: true},
                        onOk: () => {
                            DiscountCodeService.deleteBatch(selectedRowKeys.map(value => value as number)).then(() => {
                                openSuccessNotificationByMessage("کدهای تخفیف با موفقیت حذف شدند.");
                                setSelectedRowKeys([]);
                                loadDiscountCodes()
                            });
                        }
                    })
                }}>
                    <span>حذف دسته ای</span>
                    <span>({selectedRowKeys.length})</span>
                </Button>
            </Space>
            <Table<DiscountCode>
                loading={dataLoading}
                rowSelection={rowSelection}
                className="admin-course-table"
                scroll={{x: 900}}
                pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: 10,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            <div>
                {
                    (() => {
                        return (showCreateModal ?
                            <DiscountCodeCreate show={showCreateModal} setShow={setShowCreateModal}/> : null)
                    })()
                }
                {
                    (() => {
                        return (showExcelCreateModal ? <DiscountCodeExcelCreate show={showExcelCreateModal}
                                                                                setShow={setShowExcelCreateModal}/> : null)
                    })()
                }
            </div>
            {deleteModalContextHolder}
        </Wrapper1>
    );
}