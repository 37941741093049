import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Button, Divider, Modal, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {Page, toPersianDateTime} from "../../../../services/UtilFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import NotificationService, {Notification} from "../../../../services/NotificationService";
import {NotificationCreate} from "./NotificationCreate";
import {ExclamationCircleOutlined} from "@ant-design/icons";

export const NotificationContainer: React.FC = () => {

    const [data, setData] = useState<Page<Notification>>();
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });
    const [deleteModal, deleteModalContextHolder] = Modal.useModal();
    const [dataLoading, setDataLoading] = useState<boolean>(false);

    useEffect(() => {
        loadNotifications();
    }, [params, showCreateModal]);

    const loadNotifications = () => {
        setDataLoading(true);
        NotificationService.getPage(params).then(page => {
            setData(page)
        }).finally(() => setDataLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<Notification> = [
        {
            title: 'عنوان',
            dataIndex: 'title',
            width: 200,
        },
        {
            title: 'تاریخ',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, {createdDate}) => (
                <>{toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'توضیحات',
            dataIndex: 'description',
            ellipsis: true
        },
        {
            width: 70,
            render: (_, {id}) => (
                <>
                    <a onClick={() => {
                        if (id) {
                            deleteModal.confirm({
                                title: 'آیا می خواهید اعلان را حذف کنید؟',
                                icon: <ExclamationCircleOutlined/>,
                                okText: 'بله',
                                cancelText: 'انصراف',
                                okButtonProps: {danger: true},
                                onOk: () => {
                                    NotificationService.delete(id).then(value => {
                                        loadNotifications();
                                    })
                                }
                            })
                        }
                    }}>حذف</a>
                </>
            )
        },
    ];

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>مدیریت اعلان ها</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Button style={{marginTop: "20px"}} type="primary" onClick={() => setShowCreateModal(true)}>
                <span style={{marginLeft: "5px"}}><FontAwesomeIcon icon={faPlus}/></span>
                <span>افزودن اعلان جدید</span>
            </Button>
            <Table className="admin-course-table" loading={dataLoading} scroll={{x: 600}} pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: params.pageSize,
                total: data?.totalElements,
                onChange: handleSelectPagination
            }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            <div>
                {
                    (() => {
                        return (showCreateModal ?
                            <NotificationCreate show={showCreateModal} setShow={setShowCreateModal}/> : null)
                    })()
                }
            </div>
            {deleteModalContextHolder}
        </Wrapper1>
    );
}