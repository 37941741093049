import React, {useEffect, useRef, useState} from "react";
import {Button, Divider, Flex, Form, Image, Input, Modal, Select, Upload} from "antd";
import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import CourseService, {
    Chapter,
    Course, Lesson,
    LessonAccessLevel,
    LessonAccessLevelTitle
} from "../../../../services/CourseService";
import {openErrorNotification, openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";
import TextArea from "antd/es/input/TextArea";
import ChunkFileUploader from "../../../common/ChunkFileUploader";
const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";
const {Option} = Select;

interface Props {
    show: boolean;
    setShow(b: boolean): void;
    lesson?: Lesson | undefined;
    courseId: number | undefined;
}

export const LessonUpdate = ({show, setShow, lesson, courseId}: Props) => {

    const [form] = Form.useForm();
    const [coverFile, setCoverFile] = useState<RcFile | null>();
    const [videoFilePath, setVideoFilePath] = useState<string | null>(null);
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [ submitLoading, setSubmitLoading ] = useState<boolean>(false);
    const [lessonDeleteModal, lessonDeleteModalContextHolder] = Modal.useModal();
    const fileUploaderRef = useRef<any>(null);

    useEffect(() => {
        if (courseId){
            CourseService.getLessonsForAdmin(courseId).then(data => {
                setChapters(data)
            });
        }
    }, []);

    const onFinish = (values: any) => {
        values = {...values, cover: coverFile, videoFilePath: videoFilePath}
        setSubmitLoading(true);
        CourseService.updateLesson(lesson?.id, values).then(response => {
            form.resetFields();
            setCoverFile(null);
            setVideoFilePath(null);
            setSubmitLoading(false);
            openSuccessNotificationByMessage("درس با موفقیت ویرایش شد.", 0);
            setShow(false);
        }).catch(e => {
            setSubmitLoading(false);
            openErrorNotification("خطایی هنگام ویرایش رخ داده است.", 0);
        });
    }

    const justAllowNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, e.target.value.replace(/\D/g,''))
    };

    return (
        <Modal
            title="ویرایش درس"
            style={{marginTop:'10px', marginBottom:'10px'}}
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                if (!submitLoading){
                    form.resetFields();
                    setCoverFile(null);
                    setVideoFilePath(null);
                    setChapters([]);
                    if (fileUploaderRef.current) fileUploaderRef.current.handleCancelUpload(); // Cancel upload
                    setShow(false);
                }
            }}
            footer={[]}
            closable={!submitLoading}
            width={720}
        >
            <div>
            <Form
                form={form}
                name="basic"
                wrapperCol={{span: 24}}
                style={{width: '100%'}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
            >
                <Form.Item name="title" label='عنوان درس' initialValue={lesson?.title} rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                    <Input
                        placeholder="عنوان درس"
                    />
                </Form.Item>
                <Form.Item name="number" label='"شماره درس' initialValue={lesson?.number}><Input name="number" onChange={justAllowNumbers} placeholder="شماره درس"/></Form.Item>
                <Form.Item name="description" label='توضیحات' initialValue={lesson?.description}><TextArea placeholder="توضیحات" rows={4} /></Form.Item>
                <Form.Item rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                    <Upload
                        accept=".jpeg, .jpg, .png"
                        maxCount={1}
                        listType="picture"
                        beforeUpload={(file) => {
                            setCoverFile(file);
                            return false;
                        }}
                        onRemove={(file) => {
                            setCoverFile(null);
                        }}
                    >
                        <Button icon={<UploadOutlined/>}>انتخاب عکس کاور</Button>
                    </Upload>
                    <Image
                        style={{marginTop:'15px'}}
                        width={100}
                        src={baseNonVideoUrl + lesson?.coverUrl}
                    />
                </Form.Item>
                <Form.Item>
                    <ChunkFileUploader ref={fileUploaderRef} title="انتخاب ویدیو" maxSizeMb={2000} accept=".mp4, .mkv" onUploadComplete={videoFilePath => setVideoFilePath(videoFilePath)} onRemove={() => setVideoFilePath(null)}/>
                </Form.Item>
                <Form.Item name="accessLevel" label='دسترسی ویدیو' initialValue={lesson?.accessLevel} rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                    <Select
                        placeholder="دسترسی ویدیو"
                    >
                        <Option value={LessonAccessLevel.FREE}>{LessonAccessLevelTitle[LessonAccessLevel.FREE]}</Option>
                        <Option value={LessonAccessLevel.USER}>{LessonAccessLevelTitle[LessonAccessLevel.USER]}</Option>
                        <Option
                            value={LessonAccessLevel.BUYER}>{LessonAccessLevelTitle[LessonAccessLevel.BUYER]}</Option>
                    </Select>
                </Form.Item>
                <Divider orientation="left">انتخاب فصل</Divider>
                <Form.Item name="chapterId" label='فصل' initialValue={lesson?.chapterId}>
                    <Select
                        placeholder="فصل"
                        allowClear={true}
                    >
                        {
                            chapters.map(chapter => {
                                return (<Option value={chapter.id}>{"فصل " + chapter.number + " - " + chapter.title}</Option>)
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="chapterTitle" label='عنوان فصل'><Input placeholder="عنوان فصل"/></Form.Item>
                <Form.Item name="chapterNumber" label='شماره فصل'><Input name="chapterNumber" onChange={justAllowNumbers} placeholder="شماره فصل"/></Form.Item>
                <Form.Item><Button type="primary" htmlType="submit" loading={submitLoading}>ویرایش</Button></Form.Item>
            </Form>
                <Flex justify='end'>
                    <Button style={{}} type="primary" danger onClick={() => {
                        lessonDeleteModal.confirm({
                            title: 'آیا می خواهید این درس را حذف کنید؟',
                            icon: <ExclamationCircleOutlined />,
                            okText: 'بله',
                            cancelText: 'انصراف',
                            okButtonProps:{danger:true},
                            onOk: () => {
                                CourseService.deleteLesson(lesson?.id).then(() => setShow(false));
                            }
                        })
                    }}>حذف درس</Button>
                </Flex>
            </div>
            {lessonDeleteModalContextHolder}
        </Modal>
    );

}