import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Modal, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {Page} from "../../../../services/UtilFunctions";
import ContactUsService, {ContactUs} from "../../../../services/ContactUsService";

export const ContactUsContainer: React.FC = () => {

    const [data, setData] = useState<Page<ContactUs>>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey:"createdDate",
        sort:"DESC"
    });
    const [show, setShow] = useState<boolean>(false);
    const [selectedDescription, setSelectedDescription] = useState<string>();
    const [dataLoading, setDataLoading] = useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, [params]);

    const loadData = () => {
        setDataLoading(true);
        ContactUsService.getPage(params).then(page => {
            setData(page)
        }).finally(() => setDataLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<ContactUs> = [
        {
            title: 'عنوان',
            dataIndex: 'title',
            width: 200,
        },
        {
            title: 'توضیحات',
            dataIndex: 'description',
            width: 100,
            render: (_, {description}) => (
                <a onClick={event => {
                   setSelectedDescription(description);
                   setShow(true);
                }}>مشاهده</a>
            ),
        },
        {
            title: 'شماره همراه',
            width: 150,
            render: (_, contactUs) => (
                <>
                    {contactUs.user.mobile}
                </>
            )
        },
        {
            title: 'کاربر',
            width: 200,
            render: (_, contactUs) => (
                <>
                    {contactUs.user.firstName + " " + contactUs.user.lastName}
                </>
            )
        }
    ];

    return (
        <Wrapper1 style={{margin: "15px", marginTop:"0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>تماس با ما</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Table className="admin-course-table" loading={dataLoading} scroll={{x:700}} pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: params.pageSize,
                total: data?.totalElements,
                onChange: handleSelectPagination
            }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            <Modal
                title="توضیحات"
                centered
                open={show}
                onOk={() => {}}
                onCancel={() => {
                    setShow(false);
                }}
                footer={[]}
                bodyStyle={{maxHeight:"700px", overflow: 'auto'}}
                width={720}
            >
                <p style={{textAlign:"justify", whiteSpace: 'pre-line'}}>{selectedDescription}</p>
            </Modal>
        </Wrapper1>
    );
}