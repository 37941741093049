import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Button, Divider, Modal, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ColumnsType} from "antd/es/table";
import {numberWithCommas, Page} from "../../../../services/UtilFunctions";
import PlanService, {Plan} from "../../../../services/PlanService";
import {PlanCreate} from "./PlanCreate";
import {PlanUpdate} from "./PlanUpdate";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Simulate} from "react-dom/test-utils";

export const PlanContainer: React.FC = () => {

    const [data, setData] = useState<Page<Plan>>();
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [selectedPlan, setSelectedPlan] = useState<Plan>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
    });
    const [deleteModal, deleteModalContextHolder] = Modal.useModal();
    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const columns: ColumnsType<Plan> = [
        {
            title: 'عنوان',
            dataIndex: 'title',
            width: 120,
        },
        {
            title: 'قیمت',
            dataIndex: 'price',
            width: 100,
            render : (_, {price}) => (
                <>{numberWithCommas(price)}</>
            )
        },
        {
            title: 'درصد تخفیف',
            dataIndex: 'discountPercentage',
            width: 100,
        },
        {
            title: 'تعداد روز اشتراک',
            dataIndex: 'days',
            width: 100,
        },
        {
            dataIndex: 'id',
            width: 100,
            render: (_, plan) => (
                <>
                    <a onClick={()=>{
                        setSelectedPlan(plan);
                        setShowUpdateModal(true);
                    }}>ویرایش</a>
                    <span> | </span>
                    <a onClick={() => {
                        deleteModal.confirm({
                            title: 'آیا می خواهید این اشتراک را حذف کنید؟',
                            icon: <ExclamationCircleOutlined />,
                            okText: 'بله',
                            cancelText: 'انصراف',
                            okButtonProps:{danger:true},
                            onOk: () => {
                                PlanService.delete(plan.id).then(() => loadPlans());
                            }
                        })
                    }}>حذف</a>
                </>
            )
        }
    ];

    useEffect(() => {
        loadPlans();
    }, [showCreateModal, params, showUpdateModal]);

    const loadPlans = () => {
        setDataLoading(true);
        PlanService.getPlansForAdmin(params).then(page => {
            setData(page)
        }).finally(() => setDataLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    return (
        <Wrapper1 style={{margin: "15px", marginTop:"0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>مدیریت اشتراک ها</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Button style={{marginTop: "20px"}} type="primary" onClick={() => setShowCreateModal(true)}>
                <span style={{marginLeft: "5px"}}><FontAwesomeIcon icon={faPlus}/></span>
                <span>افزودن اشتراک جدید</span>
            </Button>
            <Table className="admin-course-table" loading={dataLoading} scroll={{ x: 520 }} pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: 10,
                total: data?.totalElements,
                onChange: handleSelectPagination
            }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            <div>
                {
                    (() => {
                        return( showCreateModal ?  <PlanCreate show={showCreateModal} setShow={setShowCreateModal}/> : null)
                    })()
                }
                {
                    (() => {
                        return( showUpdateModal ?  <PlanUpdate show={showUpdateModal} setShow={setShowUpdateModal} data={selectedPlan}/> : null)
                    })()
                }
            </div>
            {deleteModalContextHolder}
        </Wrapper1>
    );
}