import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {numberWithCommasToman, Page} from "../../../../services/UtilFunctions";
import {SearchFilterInput} from "../../../common/SearchFilterInput";
import WalletService, {WalletOutputModel} from "../../../../services/WalletService";

export const WalletContainer: React.FC = () => {

    const [data, setData] = useState<Page<WalletOutputModel>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10
    });
    useEffect(() => {
        load();
    }, [params]);

    const load = () => {
        setLoading(true);
        WalletService.getWalletPage(params).then(page => {
            setData(page)
        }).finally(() => setLoading(false));
    }

    const columns: ColumnsType<WalletOutputModel> = [
        {
            title: 'نام کاربری',
            dataIndex: ['user', 'username'],
            width: 200,
            render: (_, {user}) => (
                <>{user && user.username}</>
            ),
        },
        {
            title: 'نام کاربر',
            dataIndex: 'userFullName',
            width: 200,
            render: (_, {user}) => (
                <>{user && user.firstName + " " + user.lastName}</>
            ),
        },
        {
            title: 'باقی مانده',
            dataIndex: 'balance',
            width: 200,
            render: (value) => numberWithCommasToman(value)
        },
        {
            title: 'مبلغ بلوکه شده',
            dataIndex: 'blockedBalance',
            width: 200,
            render: (value) => numberWithCommasToman(value)
        },
    ];

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    return (
        <div style={{paddingBottom: '100px'}}>
            <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
                <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>کیف پول کاربران</div>
                <Divider style={{margin: '0', marginTop: '15px'}}/>
                <Space style={{marginTop: "20px"}}>
                    <SearchFilterInput sendRequest={(inputValue: string) => {
                        setParams(prevState => {
                            return {...prevState, searchParam: inputValue, pageNumber: 0}
                        })
                    }}/>
                </Space>
                <Table className="admin-course-table" scroll={{x: 800}} loading={loading} pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: params.pageSize,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            </Wrapper1>

        </div>
    );
}