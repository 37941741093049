import axios from 'axios';
import StorageService from "./StorageService";
import {genericApiCall, handleError} from "./UtilFunctions";
import {HOME_PAGE_ROUTE, LOGIN_WITH_REDIRECT_ROUTE} from "../routes/route-path";
import UserService from "./UserService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";
const loginUrl = process.env.REACT_APP_LOGIN || "";
const basicAuth = process.env.REACT_APP_BASIC_AUTH || "";

interface Token {
    access_token: string;
    refresh_token: string;
}

interface User extends Token{

}

class TokenService {
    private static instance: TokenService;

    private constructor() {}

    public static getInstance(): TokenService {
        if (!TokenService.instance) {
            TokenService.instance = new TokenService();
        }
        return TokenService.instance;
    }

    async login(username:string, password:string): Promise<void> {
        try {
            const ref = StorageService.getItem("ref");
            const data = new URLSearchParams();
            data.append('username', username);
            data.append('password', password);
            if (ref) {
                data.append("refAffiliateKey", ref);
            }
            const response = await axios.post<Token>(baseUrl + loginUrl, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            StorageService.setItem("token", response.data);
        } catch (error) {
            handleError(error);
            throw error;
        }
    }

    logout() {
        genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/api/v1/tokens/logout",{}, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getInstance().getToken()
                }
            });
        }).finally(() => {
            StorageService.removeItem("token");
            StorageService.removeItem("ref");
            document.location.href = HOME_PAGE_ROUTE;
        })
    }

    isLogin(): boolean {
        return StorageService.getItem("token");
    }

    async updateToken(): Promise<void> {
        try {
            const data = {
                refreshToken: await this.getRefreshToken()
            }
            const response = await axios.post<Token>(baseUrl + "/public/v1/tokens/refresh", data, {

            });
            StorageService.setItem("token", response.data);
            // @ts-ignore
            const profile = await UserService.getSelfProfile();
        } catch (error) {
            StorageService.removeItem("token");
            document.location.href = LOGIN_WITH_REDIRECT_ROUTE;
        }
    }

    async getToken(): Promise<string | null> {
        const user : User = StorageService.getItem("token");
        if (user){
            return user.access_token;
        }else {
            document.location.href = LOGIN_WITH_REDIRECT_ROUTE;
            return null;
        }
    }

    getTokenWithoutRedirect(): string | null {
        const user : User = StorageService.getItem("token");
        if (user){
            return user.access_token;
        }else {
            return null;
        }
    }

    async getOptionalToken(): Promise<string | null> {
        const user : User = StorageService.getItem("token");
        if (user){
            return user.access_token;
        }else {
            return null;
        }
    }

    async getRefreshToken(): Promise<string | null> {
        const user : User = StorageService.getItem("token");
        if (user){
            return user.refresh_token;
        }else {
            document.location.href = LOGIN_WITH_REDIRECT_ROUTE;
            return null;
        }
    }

}

export default TokenService.getInstance();