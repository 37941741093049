import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Row} from "antd";
import {Wrapper1} from "../../wrapper/Wrapper1";
import {SECOND_COLOR, THIRD_COLOR} from "../../../constans/colors";
import {useNavigate, useParams} from "react-router-dom";
import {BUY_PLAN_ROUTE} from "../../../routes/route-path";
import CourseService, {CategoryCoursesOutputModel} from "../../../services/CourseService";
import UserPlanService from "../../../services/UserPlanService";
import {Category} from "../../../services/CategoryService";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reduxStore";
import {CourseCard} from "../../course/CourseCard";
import {LazyImage} from "../../common/LazyImage";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

interface Props {
    setPageName : any
}

export const CategoryCourses = ({setPageName} : Props) => {

    const navigate = useNavigate();
    const {id} = useParams();
    const [courses, setCourses] = useState<CategoryCoursesOutputModel>();
    const categories: Category[] = useSelector((state: RootState) => state.categories);
    const [loadCourses, setLoadCourses] = useState<boolean>(true);
    const [activePlane, setActivePlane] = useState<boolean>(false);
    const [category, setCategory] = useState<Category>()

    useEffect(() => {
        if (id) {
            CourseService.getCategoryCourses(parseInt(id)).then(courses => {
                setCourses(courses);
                setLoadCourses(false);
            });
            UserPlanService.checkActivePlan().then(value => {
                setActivePlane(value);
            });
            if (categories && categories.length > 0){
                const category = categories.find(value => value.id === parseInt(id));
                setPageName(category?.title);
                setCategory(category);
            }
        }
    }, [categories, id])

    return (
        <div className="container">
            <Row gutter={[16, 16]} align="top">
                <Col sm={16} xs={24}>
                    <LazyImage style={{width: "100%", borderRadius: "10px"}}
                               alt={category?.title ? category?.title : ''}
                               src={baseNonVideoUrl + category?.coverUrl} />
                </Col>
                <Col sm={8} xs={24}>
                    <Wrapper1 style={{padding: "15px", color: THIRD_COLOR, lineHeight:"30px", fontWeight:'bold'}}>
                        <p>
                            <span>
                                دوره‌های موجود
                            </span>
                            <span style={{float: "left", color: SECOND_COLOR}}>
                                {courses && courses.existingCoursesCounts}
                            </span>
                        </p>
                        <p>
                            <span>
                               به زودی
                            </span>
                            <span style={{float: "left", color: SECOND_COLOR}}>
                                {courses && courses.recordingCoursesCounts}
                            </span>
                        </p>
                        <p>
                            {
                                activePlane ?
                                    <Button style={{width: "100%"}} size="large" type="default" onClick={event => navigate(BUY_PLAN_ROUTE)}>
                                        شما اشتراک فعال دارید (رزرو اشتراک)
                                    </Button> :
                                    <Button style={{width: "100%"}} size="large" type="primary" onClick={event => navigate(BUY_PLAN_ROUTE)}>خرید اشتراک دانشگاهینو</Button>
                            }

                        </p>
                    </Wrapper1>
                </Col>
                <Col span={24}>
                    <Wrapper1 style={{padding: "15px", color: THIRD_COLOR}}>
                        <h3 style={{color: SECOND_COLOR, fontWeight:'bold'}}>لیست دوره‌ها</h3>
                        <Divider style={{marginTop: '15px', marginBottom: '15px'}}/>
                        <Row gutter={[16, 16]} align="middle" justify="start"
                             style={{padding: "16px"}}>
                            {
                                courses && courses.courses.map(course => {
                                    return (
                                        <Col xl={8} md={12} sm={24} xs={24} style={{textAlign: 'center'}}>
                                            <CourseCard cardProperty={{
                                                id: course.id,
                                                title: course.title,
                                                sessionCount: course.sessionsCounts,
                                                sessionHour: course.sessionsMinutes,
                                                img: course.coverUrl,
                                                status:course.courseStatus,
                                                tag:course.courseTag
                                            }}/>
                                        </Col>
                                    );
                                })
                            }

                        </Row>
                    </Wrapper1>
                </Col>
            </Row>
        </div>
    );
}