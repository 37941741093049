import {Button, Checkbox, CheckboxChangeEvent, Drawer, Flex, Form, Input, Modal} from "antd";
import {openErrorNotification} from "../../../../services/UtilFunctions";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import WithdrawRequestService from "../../../../services/WithdrawRequestService";
import React, {useEffect, useState} from "react";
import WalletService, {WalletOutputModel} from "../../../../services/WalletService";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    loadData: () => void;
    cardNumber?: string;
}

export const WithdrawRequestDrawer = ({isOpen, onClose, loadData, cardNumber}: Props) => {

    const [rawRequestAmount, setRawRequestAmount] = useState<number>(0);
    const [requestAmount, setRequestAmount] = useState<string>("");
    const [requestModal, requestModalContextHolder] = Modal.useModal();
    const [useFullBalance, setUseFullBalance] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [wallet, setWallet] = useState<WalletOutputModel>({balance: 0, blockedBalance: 0});

    useEffect(() => {
        WalletService.getSelfWallet().then(value => setWallet(value));
    }, []);

    const reset = () => {
        setRequestAmount("");
        setRawRequestAmount(0);
        setUseFullBalance(false);
    }

    const sendRequest = () => {
        if (!cardNumber) {
            openErrorNotification("برای درخواست برداشت وجه، شماره کارت خود را وارد نمایید.")
            return;
        }
        requestModal.confirm({
            title: `مبلغ شما به شماره کارت زیر واریز خواهد شد آن را تایید می نمایید؟`,
            content: <span style={{fontWeight: 'bold'}}>{cardNumber}</span>,
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            okText: 'تایید',
            cancelText: 'انصراف',
            onOk: () => {
                let amount;
                if (useFullBalance) amount = wallet.balance;
                if (rawRequestAmount && rawRequestAmount > 0) amount = rawRequestAmount;
                if (!amount) {
                    openErrorNotification('لطفا مبلغ مدنظر خود را وارد کنید');
                    return;
                }
                setLoadingSubmit(true);
                WithdrawRequestService.requestWithdraw({
                    amount: amount
                }).then(value => {
                    loadData();
                    onClose();
                    window.dispatchEvent(new CustomEvent('wallet:updated'));
                    reset();
                }).finally(() => {
                    setLoadingSubmit(false);
                })
            }
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/,/g, ""); // Remove commas for processing

        if (!/^\d*$/.test(value)) return; // Only allow numbers

        let numValue = Number(value);
        if (numValue < 0 || numValue > wallet.balance) return; // Validate range

        setRequestAmount(formatNumber(value));
        setRawRequestAmount(numValue);
    };

    const formatNumber = (value: string): string => {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setRequestAmount(formatNumber(wallet.balance.toString()));
            setRawRequestAmount(wallet.balance);
            setUseFullBalance(true);
        } else {
            reset();
        }
    };

    return (
        <Drawer
            title='درخواست برداشت وجه'
            onClose={onClose}
            open={isOpen}
            placement="left"
            width={576}
            destroyOnClose
        >
            {requestModalContextHolder}
            <Form size="large" layout="vertical" onFinish={sendRequest} style={{
                maxWidth: 600, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
            }}>
                <div style={{flex: 1, overflow: 'auto'}}>
                    <Form.Item label='مبلغ به تومان'>
                        <Input
                            style={{maxWidth: '300px'}}
                            placeholder="مبلغ را وارد کنید"
                            value={requestAmount}
                            onChange={handleChange}
                            disabled={useFullBalance}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Checkbox checked={useFullBalance} onChange={handleCheckboxChange}>
                            برداشت کل موجودی
                        </Checkbox>
                    </Form.Item>
                </div>

                <Flex justify="space-between" gap="24px">
                    <Button block size="large" onClick={onClose}>
                        بازگشت
                    </Button>
                    <Button size='large' block loading={loadingSubmit} type="primary" htmlType="submit"
                            disabled={!wallet || wallet.balance === 0}>
                        ارسال درخواست
                    </Button>
                </Flex>

            </Form>
        </Drawer>
    );
}