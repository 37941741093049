import React, {ReactElement, useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, Modal, Select, Switch, Upload} from "antd";
import CategoryService, {Category} from "../../../../services/CategoryService";
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import UserService, {Role, RoleTitle, User} from "../../../../services/UserService";
import TextArea from "antd/es/input/TextArea";
import CourseService, {CourseType, CourseTypeTitle} from "../../../../services/CourseService";
import {openErrorNotification, openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";
import {CourseStatus, CourseStatusTitle, CourseTag, CourseTagTitle} from "../../../../constans/enums";
const { Option } = Select;

interface Props {
    show: boolean

    setShow(b: boolean): void
}

export const CourseCreate = ({show, setShow}: Props) => {

    const [form] = Form.useForm();
    const [ file, setFile ] = useState<RcFile | null>();
    const [ presentationFile, setPresentationFile ] = useState<RcFile | null>();
    const [ categories, setCategories ] = useState<Category[]>([]);
    const [ teachers, setTeachers ] = useState<User[]>([]);
    const [ submitLoading, setSubmitLoading ] = useState<boolean>(false);
    const [ showParticipationPercentage, setShowParticipationPercentage ] = useState<boolean>(false);

    const onFinish = (values: any) => {
        if (!file){
            openErrorNotification("لطفا کاور را انتخاب کنید.");
            return;
        }
        values = {...values, cover:file, presentationFile:presentationFile}
        setSubmitLoading(true);
        CourseService.addCourse(values).then(response => {
            form.resetFields();
            setFile(null);
            setPresentationFile(null);
            setShow(false);
            setSubmitLoading(false);
            openSuccessNotificationByMessage("دوره با موفقیت اضافه شد.");
        }).catch(e => setSubmitLoading(false));
    }

    useEffect(() => {
        CategoryService.getCategoriesForAdmin().then(categories => setCategories(categories));
        UserService.getUsers({
            pageNumber: 0,
            pageSize: 500,
            role:Role.ROLE_TEACHER
        }).then(page => setTeachers(page.content))
    }, []);

    const justAllowNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, e.target.value.replace(/\D/g,''))
    };

    return (
        <Modal
            title="افزودن دوره جدید"
            style={{marginTop:'10px', marginBottom:'10px'}}
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setFile(null);
                setPresentationFile(null)
                setShow(false);
            }}
            footer={[]}
            width={720}
        >
            <Form
                form={form}
                name="basic"
                wrapperCol={{span: 24}}
                style={{width: '100%'}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
            >
                <Form.Item name="title" label='عنوان' rules={[{required: true, message: 'این فیلد الزامی است.'}]}><Input placeholder="عنوان"/></Form.Item>
                <Form.Item name="description" label='توضیحات'><TextArea placeholder="توضیحات" rows={4} /></Form.Item>
                <Form.Item name="resources" label='منابع'><TextArea placeholder="منابع" rows={4} /></Form.Item>
                <Form.Item rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                    <Upload
                        accept=".jpeg, .jpg, .png"
                        listType="picture"
                        maxCount={1}
                        beforeUpload={(file) => {
                            setFile(file);
                            return false;
                        }}
                        onRemove={(file) => {
                            setFile(null);
                        }}
                    >
                        <Button icon={<UploadOutlined />}>انتخاب عکس کاور</Button>
                    </Upload>
                </Form.Item>
                <Form.Item>
                    <Upload
                        accept=".pdf, .zip, .rar"
                        listType="picture"
                        maxCount={1}
                        beforeUpload={(file) => {
                            setPresentationFile(file);
                            return false;
                        }}
                        onRemove={(file) => {
                            setPresentationFile(null);
                        }}
                    >
                        <Button icon={<UploadOutlined />}>انتخاب جزوه دوره</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="categoryIds" label='دسته بندی' rules={[{ required: true, message: 'این فیلد الزامی است.'}]}>
                    <Select
                        placeholder="دسته بندی"
                        mode="multiple"
                    >
                        {
                            categories.map(category => {
                                return (<Option value={category.id}>{category.title}</Option>)
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="teacherId" label='مدرس دوره'>
                    <Select
                        placeholder="مدرس دوره"
                        allowClear
                    >
                        {
                            teachers.map(teacher => {
                                return (<Option value={teacher.id}>{teacher.firstName + " " + teacher.lastName}</Option>)
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="courseStatus" label='وضعیت دوره'>
                    <Select
                        placeholder="وضعیت دوره"
                        allowClear
                    >
                        <Option value={CourseStatus.COMING_SOON}>{CourseStatusTitle[CourseStatus.COMING_SOON]}</Option>
                        <Option value={CourseStatus.IS_RECORDING}>{CourseStatusTitle[CourseStatus.IS_RECORDING]}</Option>
                        <Option value={CourseStatus.COMPLETED}>{CourseStatusTitle[CourseStatus.COMPLETED]}</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="courseTag" label='برچسب دوره'>
                    <Select
                        placeholder="برچسب دوره"
                        allowClear
                    >
                        <Option value={CourseTag.EXERCISE_SOLUTION}>{CourseTagTitle[CourseTag.EXERCISE_SOLUTION]}</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="type" label='نوع دوره'>
                    <Select
                        placeholder="نوع دوره"
                        allowClear
                        onChange={value => {
                            if (value === CourseType.CONTRACT) setShowParticipationPercentage(true);
                            else {
                                form.setFieldValue('participationPercentage', null);
                                setShowParticipationPercentage(false);
                            }
                        }}
                    >
                        <Option value={CourseType.CONTRACT}>{CourseTypeTitle[CourseType.CONTRACT]}</Option>
                        <Option value={CourseType.OWNER}>{CourseTypeTitle[CourseType.OWNER]}</Option>
                    </Select>
                </Form.Item>
                {
                    showParticipationPercentage &&
                        <Form.Item
                            name="participationPercentage"
                            label='درصد مشارکت'
                            rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                        >
                            <InputNumber style={{width:"100%"}} min={0} max={100} placeholder="درصد مشارکت" />
                        </Form.Item>
                }
                <Form.Item name="priorityNumber" label='الویت نمایش دوره'>
                    <Input name="priorityNumber" onChange={justAllowNumbers} placeholder="الویت نمایش دوره"/>
                </Form.Item>
                <Form.Item name="active" label="فعال" initialValue={true}>
                    <Switch  defaultChecked checkedChildren={true} unCheckedChildren={false}/>
                </Form.Item>
                <Form.Item><Button type="primary" htmlType="submit" loading={submitLoading}>افزودن</Button></Form.Item>
            </Form>
        </Modal>
    );

}