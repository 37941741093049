import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Rate, Row, Table, Tag} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Wrapper1} from "../wrapper/Wrapper1";
import {FIRST_COLOR, SECOND_COLOR, THIRD_COLOR, THIRD_MAIN_COLOR} from "../../constans/colors";
import type {ColumnsType} from 'antd/es/table';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faLock, faPlay} from "@fortawesome/free-solid-svg-icons";
import './course.css';
import CourseService, {Chapter, Course, Lesson, LessonAccessLevel} from "../../services/CourseService";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import {encrypt, openErrorNotification, openSuccessNotificationByMessage} from "../../services/UtilFunctions";
import TokenService from "../../services/TokenService";
import {Source} from "plyr";
import {BUY_PLAN_ROUTE, SEARCH_ROUTE, TEACHER_COURSES_ROUTE} from "../../routes/route-path";
import {useDispatch, useSelector} from "react-redux";
// @ts-ignore
import adsVideo from '../../assets/videos/daneshgahinoStartCourse.mp4';
import {Category} from "../../services/CategoryService";
import {RootState} from "../../redux/reduxStore";
import CourseRateService, {CourseRate} from "../../services/CourseRateService";
import {CourseCommentContainer} from "./CourseCommentContainer";
import {AboutTeacher} from "./AboutTeacher";
import UserPlanService from "../../services/UserPlanService";
import {LoginOrBuyPlanModal} from "./LoginOrBuyPlanModal";
import {
    CourseStatus,
    CourseStatusBadgeColor,
    CourseStatusTitle,
    CourseTagBadgeColor,
    CourseTagTitle
} from "../../constans/enums";
import {AutoLoadCoursesPreviewCarouselWrapper} from "./preview/AutoLoadCoursesPreviewCarouselWrapper";
import FileService from "../../services/FileService";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";
const baseVideoUrl = process.env.REACT_APP_BASE_VIDEO_URL || "";

interface ChapterModel extends Chapter {
    key: React.Key;
}

const columns: ColumnsType<ChapterModel> = [
    {
        title: 'عنوان',
        render: (record) => record.title
    }
];

interface Props {
    setPageName : any
}

export const CourseLessons = ({setPageName}:Props) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [course, setCourse] = useState<Course>();
    const [chapters, setChapters] = useState<ChapterModel[]>([]);
    const [selectedLesson, setSelectedLesson] = useState<Lesson>();
    const [vidIndex, setVidIndex] = useState<number>(1);
    const [param2, setParam2] = useState<string>();
    const dispatch = useDispatch();
    const categories: Category[] = useSelector((state: RootState) => state.categories);
    const [rate, setRate] = useState<CourseRate>({avg: 0, total: 0})
    const [activePlane, setActivePlane] = useState<boolean>(false);
    const [showLoginOrBuyPlanModal, setShowLoginOrBuyPlanModal] = useState<boolean>(false);
    const [showLoginOrBuyPlanVideoUrl, setShowLoginOrBuyPlanVideoUrl] = useState<string>("");
    const defaultMoreCoursesParams = {
        pageNumber: 0,
        pageSize: 20
    }

    useEffect(() => {
        FileService.generateFileToken().then(r => setParam2(r.token));
    }, []);

    useEffect(() => {
        if (id && categories.length !== 0) {
            UserPlanService.checkActivePlan().then(value => {
                setActivePlane(value);
                loadLessons(parseInt(id));
            })
            loadRate();
            CourseService.getById(parseInt(id)).then(course => {
                setCourse(course);
                setPageName(course.title);
            });
        }
    }, [categories])

    const scrollToCommentsIfNeeded = () => {
        const commentId = window.location.hash.substring(1);
        if (commentId){
            const element = document.getElementById(commentId);
            if (element) {
                setTimeout((args: any) => element.scrollIntoView({behavior: 'smooth'}), 1000)
            }
        }
    }

    const loadLessons = (id: number) => {
        CourseService.getLessonsById(id).then(chaptersResponse => {
            let find = false;
            for (const chapter of chaptersResponse.chapters) {
                for (const lesson of chapter.lessons) {
                    if (lesson.videoUrl1080 || lesson.videoUrl720 || lesson.videoUrl480) {
                        setSelectedLesson(lesson);
                        find = true;
                        break;
                    }
                }
                if (find) {
                    break;
                }
            }
            setChapters(chaptersResponse.chapters.map(record => {
                return {...record, key: record.id}
            }))
            scrollToCommentsIfNeeded();
        });
    }

    const loadRate = () => {
        if (id) {
            CourseRateService.getRatesByCourseId(parseInt(id)).then(value => setRate(value));
        }
    }


    const getVideoUrl = (videoUrl: string): string => {
        let url = baseVideoUrl + videoUrl + "?param2=" + param2;
        return videoUrl ? url : "";
    }

    const loadSources = (): Source[] => {
        const sources: Source[] = [];
        if (selectedLesson?.videoUrl1080) sources.push({
            src: getVideoUrl(selectedLesson?.videoUrl1080),
            size: 1080,
            type: "video/mp4"
        })
        if (selectedLesson?.videoUrl720) sources.push({
            src: getVideoUrl(selectedLesson?.videoUrl720),
            size: 720,
            type: "video/mp4"
        })
        if (selectedLesson?.videoUrl480) sources.push({
            src: getVideoUrl(selectedLesson?.videoUrl480),
            size: 480,
            type: "video/mp4"
        })
        return sources;
    }

    const generateLessons = (record: Chapter) => {
        return (
            record.lessons.map(lesson => {
                return (
                    <Button style={{width: "100%", marginBottom: "15px", height: "100%", display:"block", paddingTop:"10px", paddingBottom:"10px"}} size="large"
                            onClick={() => {
                                if (
                                    (lesson.videoUrl1080 || lesson.videoUrl720 || lesson.videoUrl480)
                                ){
                                    setSelectedLesson(lesson);
                                    setVidIndex(1);
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth"
                                    });
                                }else if(lesson.accessLevel === LessonAccessLevel.USER) {
                                    setShowLoginOrBuyPlanVideoUrl(baseVideoUrl + "/76a339cc-e210-4271-992a-b1abd93c9d6c/CH02-c.mp4");
                                    setShowLoginOrBuyPlanModal(true);
                                    setVidIndex(1);
                                }else if (lesson.accessLevel === LessonAccessLevel.FREE){
                                    openErrorNotification("این قسمت هنوز آماده نشده است.", 5);
                                }else {
                                    setShowLoginOrBuyPlanVideoUrl(baseVideoUrl + "/89157c5a-3c03-466f-9bf4-13756cc504f1/CH01-c.mp4");
                                    setShowLoginOrBuyPlanModal(true);
                                    setVidIndex(1);
                                }
                            }}

                    >
                        <Row align="middle" justify="space-between">
                            <Col className="overflow-ellipsis" sm={18} xs={24} style={{textAlign: "right"}}>{lesson.title}</Col>
                            <Col sm={3} xs={12}>{lesson.duration + " دقیقه"}</Col>
                            <Col sm={3} xs={12}>{(lesson.videoUrl1080 || lesson.videoUrl720 || lesson.videoUrl480) ?
                                <FontAwesomeIcon icon={faPlay}/> : <FontAwesomeIcon icon={faLock}/>}</Col>
                        </Row>
                    </Button>
                );
            })
        )
    }

    const adsPlyrVideo = () => {
        return (
            <div className="custom-player">
                <Plyr
                    ref={instance => {
                        if (instance && instance.plyr && instance.plyr.on) {
                            instance?.plyr.on('ended', event => {
                                setVidIndex(2);
                            })
                        }
                    }}
                    poster={baseNonVideoUrl + course?.coverUrl}
                    source={{
                        type: "video",
                        sources: [
                            {
                                src: adsVideo,
                                type: "video/mp4"
                            }
                        ],
                        poster: baseNonVideoUrl + course?.coverUrl,
                    }}
                    options={{
                        enabled: vidIndex === 1,
                        controls: ['play-large', 'fullscreen', 'play'],
                    }}
                >
                </Plyr>
            </div>
        )
    };

    const lessonVideo = () => {
        return (
            <div className="custom-player">
                    <Plyr
                        ref={instance => {
                            if (instance && instance.plyr && instance.plyr.on) {
                                let interval: number;
                                instance?.plyr.on('play', event => {
                                    interval = setInterval(async args => {
                                        const time = instance.plyr.currentTime;
                                        const minute = Math.floor(time / 60);
                                        if (course?.id && selectedLesson?.id && minute > 0) {
                                            CourseService.watchLesson(selectedLesson?.id, minute);
                                        }
                                    }, 60000);
                                })
                                instance?.plyr.on('pause', event => {
                                    clearInterval(interval);
                                })
                                instance?.plyr.on('ended', event => {
                                    setVidIndex(1);
                                })
                            }
                        }}
                        source={{
                            type: "video",
                            sources: loadSources(),
                            poster: baseNonVideoUrl + course?.coverUrl,
                        }}
                        poster={baseNonVideoUrl + course?.coverUrl}
                        options={{
                            enabled: (vidIndex === 2 && param2 !== undefined),
                            quality: {default: 720, options: [1080, 720, 480]},
                            autoplay: true,
                            speed:{ selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4] },
                            keyboard: {
                                global: true
                            },
                            seekTime: 10,
                        }}
                    >
                    </Plyr>
            </div>
        )
    };

    const buyPlanOrLoginVideo = (videoUrl: any) => {
        return (
            <div className="custom-player">
                <Plyr
                    ref={instance => {
                        if (instance && instance.plyr && instance.plyr.on) {
                            instance?.plyr.on('ended', event => {
                                setVidIndex(1);
                            })
                        }
                    }}
                    source={{
                        type: "video",
                        sources: [
                            {
                                src: videoUrl,
                                type: "video/mp4"
                            }
                        ],
                        poster: baseNonVideoUrl + course?.coverUrl,
                    }}
                    options={{
                        enabled: vidIndex === 2,
                        // controls: ['play-large', 'fullscreen', 'play'],
                        autoplay: true
                    }}
                >
                </Plyr>
            </div>
        )
    };

    const saveRate = (value: number) => {
        if (!TokenService.isLogin()) {
            openErrorNotification("برای ثبت امتیاز باید وارد سایت شوید.", 5)
            return;
        }
        if (id) {
            CourseRateService.saveRate(parseInt(id), value).then(value1 => {
                openSuccessNotificationByMessage("امتیاز شما ثبت شد.", 5);
                loadRate();
            });
        }
    }

    const handleMainVideoWithCheckingConditions = () => {
        if (!selectedLesson) return null;
      if (selectedLesson.videoUrl1080 || selectedLesson.videoUrl720 || selectedLesson.videoUrl480) return lessonVideo();
      else if(!TokenService.isLogin() && selectedLesson.accessLevel === LessonAccessLevel.USER) return buyPlanOrLoginVideo("");
      else if(TokenService.isLogin() && selectedLesson.accessLevel === LessonAccessLevel.USER){
          openErrorNotification("این قسمت هنوز آماده نشده است.", 5);
          return null;
      }
      else if(selectedLesson.accessLevel === LessonAccessLevel.BUYER) return buyPlanOrLoginVideo("");
      else {
          openErrorNotification("این قسمت هنوز آماده نشده است.", 5);
          return null;
      }
    }

    return (
        <div className="container">
            <div><h4 style={{color: THIRD_COLOR}}>{selectedLesson?.title}</h4></div>
            <Row gutter={[16, 16]} align="top">
                <Col sm={16} xs={24}>
                    {
                        course?.coverUrl ?
                            (vidIndex === 1 ?
                                    adsPlyrVideo() : handleMainVideoWithCheckingConditions()
                            ) :
                            null
                    }
                </Col>
                <Col sm={8} xs={24}>
                    <Wrapper1 style={{padding: "15px", color: THIRD_COLOR, fontWeight:'bold'}}>
                        {
                            course &&
                            (
                                <>
                                    <p>
                            <span>
                                تعداد فصل ها
                            </span>
                                        <span style={{float: "left", color: SECOND_COLOR}}>
                                {course?.chaptersCounts + " فصل"}
                            </span>
                                    </p>
                                    <p>
                            <span>
                                مدت دوره
                            </span>
                                        <span style={{float: "left", color: SECOND_COLOR}}>
                                {course?.sessionsMinutes + " دقیقه"}
                            </span>
                                    </p>
                                    <p>
                            <span>
                               تعداد جلسات
                            </span>
                                        <span style={{float: "left", color: SECOND_COLOR}}>
                                {course?.sessionsCounts + " جلسه"}
                            </span>
                                    </p>
                                    <p>
                            <span>
                               مدرس
                            </span>
                                        <span style={{float: "left", color: SECOND_COLOR}}>
                                {course?.teacher && course.teacher.firstName + " " + course.teacher.lastName}
                            </span>
                                    </p>
                                    {
                                        course.courseStatus &&
                                        <p>
                            <span>
                               وضعیت دوره
                            </span>
                                            <span style={{float: "left"}}>
                                <Tag style={{margin:0}} color={CourseStatusBadgeColor[course.courseStatus]}>{CourseStatusTitle[course.courseStatus]}</Tag>
                            </span>
                                        </p>
                                    }
                                    {
                                        course.courseTag &&
                                        <p>
                            <span>
                               برچسب دوره
                            </span>
                                            <span style={{float: "left"}}>
                                <Tag style={{margin:0}} color={CourseTagBadgeColor[course.courseTag]}>{CourseTagTitle[course.courseTag]}</Tag>
                            </span>
                                        </p>
                                    }
                                    <p style={{textAlign: "center"}}>
                                        <Rate onChange={saveRate} allowClear={false} value={rate.avg}/>
                                        <span> از مجموع {rate.total} امتیاز</span>
                                    </p>
                                    {
                                        course.presentationUrl &&
                                        <p style={{textAlign:'center'}}>
                                            <Button style={{width: "100%"}} size="large" type="primary" onClick={event => {
                                                if(activePlane){
                                                    window.open(baseNonVideoUrl + course?.presentationUrl + "?" + "param2=" + param2?.replaceAll('+', '%2B'), '_blank');
                                                }else {
                                                    openErrorNotification("برای دانلود جزوه نیاز به اشتراک فعال دارید.");
                                                }
                                            } }>
                                                <svg style={{verticalAlign:'middle', marginLeft:'4px'}} width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7 6a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V6Zm1.5 1.5h7v-1h-7v1Z" fill="#ffffff"/><path d="M4 4.5A2.5 2.5 0 0 1 6.5 2H18a2.5 2.5 0 0 1 2.5 2.5v14.25a.75.75 0 0 1-.75.75H5.5a1 1 0 0 0 1 1h13.25a.75.75 0 0 1 0 1.5H6.5A2.5 2.5 0 0 1 4 19.5v-15ZM5.5 18H19V4.5a1 1 0 0 0-1-1H6.5a1 1 0 0 0-1 1V18Z" fill="#ffffff"/></svg>
                                                دانلود جزوه دوره
                                            </Button>
                                        </p>
                                    }
                                    <p>
                                        {
                                            activePlane ?
                                                <Button style={{width: "100%"}} size="large" type="default" onClick={event => navigate(BUY_PLAN_ROUTE)}>
                                                    شما اشتراک فعال دارید (رزرو اشتراک)
                                                </Button> :
                                                <Button style={{width: "100%"}} size="large" type="default"
                                                        onClick={event => navigate(BUY_PLAN_ROUTE)}>خرید اشتراک دانشگاهینو</Button>
                                        }

                                    </p>
                                </>
                            )
                        }
                    </Wrapper1>
                </Col>
                <Col span={24}>
                    <Wrapper1 style={{padding: "15px", paddingTop: "15px", color: THIRD_COLOR, fontWeight:'bold'}}
                              topBorderColor={FIRST_COLOR}>
                        <Row gutter={[16,16]} className="course-content-list" justify="space-evenly">
                            <Col xs={8} sm={3} style={{fontSize: "inherit", textAlign: "center"}}>
                                <a
                                    onClick={() => {
                                        const element = document.getElementById('lesson-description');
                                        if (element) {
                                            element.scrollIntoView({behavior: 'smooth'});
                                        }
                                    }}
                                    style={{color: THIRD_COLOR}}>جزئیات این قسمت</a>
                            </Col>
                            <Col xs={8} sm={3} style={{fontSize: "inherit", textAlign: "center"}}>
                                <a
                                    onClick={() => {
                                        const element = document.getElementById('course-description');
                                        if (element) {
                                            element.scrollIntoView({behavior: 'smooth'});
                                        }
                                    }}
                                    style={{color: THIRD_COLOR}}>توضیحات دوره</a>
                            </Col>
                            <Col xs={8} sm={3} style={{fontSize: "inherit", textAlign: "center"}}>
                                <a
                                    onClick={() => {
                                        const element = document.getElementById('course-chapters');
                                        if (element) {
                                            element.scrollIntoView({behavior: 'smooth'});
                                        }
                                    }}
                                    style={{color: THIRD_COLOR}}>فصل ها</a>
                            </Col>
                            <Col xs={8} sm={3} style={{fontSize: "inherit", textAlign: "center"}}>
                                <a
                                    onClick={() => {
                                        const element = document.getElementById('about-teacher');
                                        if (element) {
                                            element.scrollIntoView({behavior: 'smooth'});
                                        }
                                    }}
                                    style={{color: THIRD_COLOR}}>درباره مدرس</a>
                            </Col>
                            <Col xs={8} sm={3} style={{fontSize: "inherit", textAlign: "center"}}>
                                <a
                                    onClick={() => {
                                        const element = document.getElementById('resources');
                                        if (element) {
                                            element.scrollIntoView({behavior: 'smooth'});
                                        }
                                    }}
                                    style={{color: THIRD_COLOR}}>منابع</a>
                            </Col>
                            <Col xs={8} sm={3} style={{fontSize: "inherit", textAlign: "center"}}>
                                <a
                                    onClick={() => {
                                        const element = document.getElementById('more-courses');
                                        if (element) {
                                            element.scrollIntoView({behavior: 'smooth'});
                                        }
                                    }}
                                    style={{color: THIRD_COLOR}}>دوره‌های این مدرس</a>
                            </Col>
                            <Col xs={8} sm={3} style={{fontSize: "inherit", textAlign: "center"}}>
                                <a
                                    onClick={() => {
                                        const element = document.getElementById('course-comments');
                                        if (element) {
                                            element.scrollIntoView({behavior: 'smooth'});
                                        }
                                    }}
                                    style={{color: THIRD_COLOR}}>نظرات</a>
                            </Col>
                        </Row>
                    </Wrapper1>
                </Col>
                {
                    (selectedLesson) ?
                        (
                            <Col id="lesson-description" span={24}>
                                <Wrapper1 style={{padding: "15px", color: THIRD_COLOR}}>
                                    <h3>جزئیات این قسمت</h3>
                                    <Divider style={{marginTop: '15px'}}/>
                                    <p style={{
                                        whiteSpace: 'pre-line',
                                        textAlign: 'justify'
                                    }}>{selectedLesson?.description}</p>
                                </Wrapper1>
                            </Col>
                        )
                        : ""
                }
                <Col id="course-description" span={24}>
                    <Wrapper1 style={{padding: "15px", color: THIRD_COLOR}}>
                        <h3>توضیحات دوره</h3>
                        <Divider style={{marginTop: '15px'}}/>
                        <p style={{whiteSpace: 'pre-line', textAlign: 'justify'}}>{course?.description}</p>
                    </Wrapper1>
                </Col>
                <Col id="course-chapters" span={24}>
                    <Wrapper1 style={{padding: "15px", color: THIRD_COLOR}}>
                        <h3>فصل ها</h3>
                        <Divider style={{marginTop: '15px', marginBottom: '15px'}}/>
                        <div className="course-table">
                            <Table
                                columns={columns}
                                expandable={{
                                    expandedRowRender: (record) => generateLessons(record),
                                    rowExpandable: (record) => record.lessons.length !== 0,
                                    fixed: "left",
                                    expandIcon: ({expanded, onExpand, record}) =>
                                        expanded ? (
                                            <span style={{float: "left"}}
                                                  onClick={e => onExpand(record, e)}><FontAwesomeIcon icon={faAngleUp}/></span>
                                        ) : (
                                            <span onClick={e => onExpand(record, e)}><FontAwesomeIcon
                                                icon={faAngleDown}/></span>
                                        )
                                }}
                                dataSource={chapters}
                                pagination={false}
                                showHeader={false}
                                expandRowByClick
                            />
                            {course?.courseStatus && course.courseStatus === CourseStatus.IS_RECORDING && <Tag style={{margin:0, marginTop:'10px', width:'100%', textAlign:'center', padding:'10px', fontSize:'1rem'}} color="cyan">این دوره در حال ضبط می باشد</Tag>}
                        </div>
                    </Wrapper1>
                </Col>
                <Col id="about-teacher" span={24}>
                    {course && course.teacher && <AboutTeacher teacher={course?.teacher}/>}
                </Col>
                <Col id="resources" span={24}>
                    <Wrapper1 style={{padding: "15px", color: THIRD_COLOR}}>
                        <h3>منابع</h3>
                        <Divider style={{marginTop: '15px'}}/>
                        <p style={{whiteSpace: 'pre-line', textAlign: 'justify'}}>{course?.resources}</p>
                    </Wrapper1>
                </Col>
                {
                    course?.teacher.id &&
                    <Col id="more-courses" span={24}>
                        <AutoLoadCoursesPreviewCarouselWrapper
                            dividerStyle={{margin: '0', marginTop: '15px'}}
                            headerStyle={{fontSize:'1.17em', color:THIRD_COLOR, padding: "15px 15px"}}
                            color={SECOND_COLOR} title={"دوره‌های این مدرس"}
                            defaultParams={{...defaultMoreCoursesParams, teacherId:course?.teacher.id}}/>
                    </Col>
                }
                <Col id="course-comments" span={24}>
                    {id && <CourseCommentContainer id={parseInt(id)}/>}
                </Col>
            </Row>
            {showLoginOrBuyPlanModal ?  <LoginOrBuyPlanModal show={showLoginOrBuyPlanModal} setShow={setShowLoginOrBuyPlanModal} videoUrl={showLoginOrBuyPlanVideoUrl}/> : null}
        </div>
    );
}