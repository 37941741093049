import React, {useEffect, useRef, useState} from "react";
import {Button, Divider, Form, Input, Modal, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import CourseService, {Chapter, LessonAccessLevel, LessonAccessLevelTitle} from "../../../../services/CourseService";
import TextArea from "antd/es/input/TextArea";
import ChunkFileUploader from "../../../common/ChunkFileUploader";
import {openErrorNotification, openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";

const {Option} = Select;

interface Props {
    show: boolean;

    setShow(b: boolean): void;

    courseId?: number;
}

export const LessonCreate = ({show, setShow, courseId}: Props) => {

    const [form] = Form.useForm();
    const [coverFile, setCoverFile] = useState<RcFile | null>();
    const [videoFilePath, setVideoFilePath] = useState<string | null>(null);
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const fileUploaderRef = useRef<any>(null);

    useEffect(() => {
        if (courseId) {
            CourseService.getLessonsForAdmin(courseId).then(data => {
                setChapters(data)
            });
        }
    }, []);

    const onFinish = (values: any) => {
        if (!videoFilePath) {
            openErrorNotification("لطفا ویدیو را انتخاب کنید.");
            return;
        }
        values = {...values, cover: coverFile, videoFilePath: videoFilePath}
        if (!courseId) {
            openErrorNotification("خطایی رخ داده است.");
            return;
        }
        setSubmitLoading(true);
        CourseService.addNewLesson(courseId, values).then(response => {
            form.resetFields();
            setCoverFile(null);
            setVideoFilePath(null);
            setSubmitLoading(false);
            openSuccessNotificationByMessage("درس با موفقیت آپلود شد.", 0);
            setShow(false);
        }).catch(
            e => {
                setSubmitLoading(false);
                openErrorNotification("خطایی هنگام آپلود رخ داده است.", 0);
            }
        );
    }

    const justAllowNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, e.target.value.replace(/\D/g, ''))
    };

    return (
        <Modal
            title="افزودن درس جدید"
            style={{marginTop:'10px', marginBottom:'10px'}}
            centered
            open={show}
            onOk={() => {
            }}
            onCancel={() => {
                if (!submitLoading) {
                    form.resetFields();
                    setCoverFile(null);
                    setVideoFilePath(null);
                    setChapters([]);
                    if (fileUploaderRef.current) fileUploaderRef.current.handleCancelUpload(); // Cancel upload
                    setShow(false);
                }
            }}
            footer={[]}
            closable={!submitLoading}
            width={720}
        >
            <div>
                <Form
                    form={form}
                    name="basic"
                    wrapperCol={{span: 24}}
                    style={{width: '100%'}}
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    autoComplete="off"
                    layout='vertical'
                >
                    <Form.Item name="title" label='عنوان درس' rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                        <Input
                            placeholder="عنوان درس"
                        />
                    </Form.Item>
                    <Form.Item name="number" label='شماره درس'><Input name="number" onChange={justAllowNumbers} placeholder="شماره درس"/></Form.Item>
                    <Form.Item name="description" label='توضیحات'><TextArea placeholder="توضیحات" rows={4}/></Form.Item>
                    <Form.Item rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                        <Upload
                            accept=".jpeg, .jpg, .png"
                            maxCount={1}
                            listType="picture"
                            beforeUpload={(file) => {
                                setCoverFile(file);
                                return false;
                            }}
                            onRemove={(file) => {
                                setCoverFile(null);
                            }}
                        >
                            <Button icon={<UploadOutlined/>}>انتخاب عکس کاور</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                    <ChunkFileUploader ref={fileUploaderRef} title="انتخاب ویدیو" maxSizeMb={2000} accept=".mp4, .mkv" onUploadComplete={videoFilePath => setVideoFilePath(videoFilePath)} onRemove={() => setVideoFilePath(null)}/>
                    </Form.Item>
                    <Form.Item name="accessLevel" label='دسترسی ویدیو' rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                        <Select
                            placeholder="دسترسی ویدیو"
                        >
                            <Option
                                value={LessonAccessLevel.FREE}>{LessonAccessLevelTitle[LessonAccessLevel.FREE]}</Option>
                            <Option
                                value={LessonAccessLevel.USER}>{LessonAccessLevelTitle[LessonAccessLevel.USER]}</Option>
                            <Option
                                value={LessonAccessLevel.BUYER}>{LessonAccessLevelTitle[LessonAccessLevel.BUYER]}</Option>
                        </Select>
                    </Form.Item>
                    <Divider orientation="left">انتخاب فصل</Divider>
                    <Form.Item name="chapterId" label='فصل'>
                        <Select
                            placeholder="فصل"
                            allowClear={true}
                        >
                            {
                                chapters.map(chapter => {
                                    return (<Option
                                        value={chapter.id}>{"فصل " + chapter.number + " - " + chapter.title}</Option>)
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="chapterTitle" label='عنوان فصل'><Input placeholder="عنوان فصل"/></Form.Item>
                    <Form.Item name="chapterNumber" label='شماره فصل'><Input name="chapterNumber" onChange={justAllowNumbers}
                                                           placeholder="شماره فصل"/></Form.Item>
                    <Form.Item><Button type="primary" htmlType="submit"
                                       loading={submitLoading}>افزودن</Button></Form.Item>
                </Form>
            </div>
        </Modal>
    );

}