import React, {useEffect, useState} from "react";
import {Button, Modal, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ColumnsType} from "antd/es/table";
import {openSuccessNotificationByMessage, Page} from "../../../../services/UtilFunctions";
import {EducationCreate} from "./EducationCreate";
import EducationService, {DegreeTitle, Education} from "../../../../services/EducationService";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import CourseService from "../../../../services/CourseService";

interface Props {
    userId:number;
    show:boolean
    setShow(b : boolean):void
}

export const EducationContainer = ({show, setShow, userId} : Props) => {

    const [data, setData] = useState<Page<Education>>();
    const [showCreateEducationModal, setShowCreateEducationModal] = useState<boolean>(false);
    const [showEducationDescriptionModal, setShowEducationDescriptionModal] = useState<boolean>(false);
    const [selectedDescription, setSelectedDescription] = useState<string>();
    const [educationDeleteModal, educationDeleteModalContextHolder] = Modal.useModal();

    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 100,
    });
    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const columns: ColumnsType<Education> = [
        {
            title: 'نام دانشگاه',
            dataIndex: 'university',
            ellipsis:true,
        },
        {
            title: 'مدرک',
            dataIndex: 'degree',
            ellipsis:true,
            render: (_, { degree }) => (
                <>
                    {DegreeTitle[degree]}
                </>
            ),
        },
        {
            title: 'سال شروع',
            dataIndex: 'startYear',
            width: 200,
        },
        {
            title: 'سال اتمام',
            dataIndex: 'endYear',
            ellipsis:true,
        },
        {
            title: 'توضیحات',
            dataIndex: 'description',
            ellipsis:true,
            render: (_, {description}) => (
                <a onClick={event => {
                    setSelectedDescription(description);
                    setShowEducationDescriptionModal(true);
                }}>مشاهده</a>
            ),
        },
        {
            dataIndex: 'id',
            ellipsis:true,
            render: (_, {id}) => (
                <a onClick={() => {
                    educationDeleteModal.confirm({
                        title: 'آیا می خواهید این مدرک را حذف کنید؟',
                        icon: <ExclamationCircleOutlined />,
                        okText: 'بله',
                        cancelText: 'انصراف',
                        okButtonProps:{danger:true},
                        onOk: () => {
                            EducationService.delete(id).then(() => {
                                loadEducations();
                            });
                        }
                    })
                }}>حذف</a>
            )
        }
    ];

    useEffect(() => {
        loadEducations();
    }, [show, params, showCreateEducationModal]);

    const loadEducations = () => {
        setDataLoading(true);
        EducationService.getPage(userId, params).then(page => {
            setData(page)
        }).finally(() => setDataLoading(false));
    }

    return (
    <Modal
        title="تحصیلات"
        centered
        open={show}
        onOk={() => {}}
        onCancel={() => {
            setShow(false);
        }}
        footer={[]}
        bodyStyle={{maxHeight:"700px", overflow: 'auto'}}
        width={900}
    >
        <Button style={{marginTop: "20px"}} type="primary" onClick={() => setShowCreateEducationModal(true)}>
            <span style={{marginLeft: "5px"}}><FontAwesomeIcon icon={faPlus}/></span>
            <span>افزودن مدرک</span>
        </Button>
        <Table className="admin-course-table" loading={dataLoading} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
        {
            (() => {
                return( showCreateEducationModal && userId ?  <EducationCreate show={showCreateEducationModal} setShow={setShowCreateEducationModal} userId={userId}/> : null)
            })()
        }
        <Modal
            title="توضیحات"
            centered
            open={showEducationDescriptionModal}
            onOk={() => {}}
            onCancel={() => {
                setShowEducationDescriptionModal(false);
            }}
            footer={[]}
            bodyStyle={{maxHeight:"700px", overflow: 'auto'}}
            width={900}
        >
            <p style={{textAlign:"justify", whiteSpace: 'pre-line'}}>{selectedDescription}</p>
        </Modal>
        {educationDeleteModalContextHolder}
    </Modal>
    );
}