import React, {useState} from "react";
import {Button, Flex, Form, Input, InputNumber, Modal, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import EducationService, {Degree, DegreeTitle, Education} from "../../../../services/EducationService";
import {openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";

const { Option } = Select;
interface Props {
    education:Education;
    show:boolean
    setShow(b : boolean):void
}

export const TeacherEducationUpdate =({show, setShow, education} : Props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        setLoading(true);
        EducationService.selfUpdate(education.id, values).then(response => {
            form.resetFields();
            setShow(false);
            setLoading(false);
            openSuccessNotificationByMessage("مدرک با موفقیت ویرایش شد.", 5);
        }).catch(reason => {
            setLoading(false);
        });
    }

    return(
        <Modal
            title="افزودن مدرک تحصیلی"
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
            >
                <Form.Item
                    name="university"
                    label='نام دانشگاه'
                    initialValue={education.university}
                >
                    <Input placeholder="نام دانشگاه"/>
                </Form.Item>

                <Form.Item label='مدرک' initialValue={education.degree} name="degree" rules={[{ required: true, message: 'این فیلد الزامی است.'}]}>
                    <Select
                        placeholder="مدرک"
                    >
                        <Option value={Degree.ASSOCIATE}>{DegreeTitle[Degree.ASSOCIATE]}</Option>
                        <Option value={Degree.BACHELOR}>{DegreeTitle[Degree.BACHELOR]}</Option>
                        <Option value={Degree.MASTER}>{DegreeTitle[Degree.MASTER]}</Option>
                        <Option value={Degree.MASTER_STUDENT}>{DegreeTitle[Degree.MASTER_STUDENT]}</Option>
                        <Option value={Degree.DOCTORAL}>{DegreeTitle[Degree.DOCTORAL]}</Option>
                        <Option value={Degree.DOCTORAL_STUDENT}>{DegreeTitle[Degree.DOCTORAL_STUDENT]}</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="startYear"
                    label='سال شروع'
                    initialValue={education.startYear}
                >
                    <InputNumber style={{width:"100%"}} min={1300} max={1500} placeholder="سال شروع" />
                </Form.Item>

                <Form.Item
                    name="endYear"
                    label='سال اتمام'
                    initialValue={education.endYear}
                >
                    <InputNumber style={{width:"100%"}} min={1300} max={1500} placeholder="سال اتمام" />
                </Form.Item>
                <Form.Item name="description" label='توضیحات' initialValue={education.description}><TextArea placeholder="توضیحات" rows={4} /></Form.Item>

                <Flex justify='end'>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            ویرایش
                        </Button>
                    </Form.Item>
                </Flex>
            </Form>
        </Modal>
    );

}