import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Spin, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import {
    numberWithCommasToman,
    openSuccessNotificationByMessage,
    Page,
    toPersianDateTime
} from "../../../../services/UtilFunctions";
import {
    TransactionStatus,
    TransactionStatusColor,
    TransactionStatusTitle,
    TransactionTypeColor,
    TransactionTypePriceColor,
    TransactionTypeTitle
} from "../../../../constans/enums";
import TransactionService, {AdminTransactionOutputModel} from "../../../../services/TransactionService";
import {ReloadOutlined} from "@ant-design/icons";
import PaymentService from "../../../../services/PaymentService";

export const AdminTransactionContainer: React.FC = () => {

    const [data, setData] = useState<Page<AdminTransactionOutputModel>>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [isLoadingVerify, setIsLoadingVerify] = useState<string | null>(null)
    useEffect(() => {
        loadData();
    }, [params]);

    const loadData = () => {
        setDataLoading(true);
        TransactionService.getTransactions(params).then(page => {
            setData(page)
        }).finally(() => setDataLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<AdminTransactionOutputModel> = [
        {
            title: 'شماره همراه',
            dataIndex: 'mobileNumber',
            width: 200
        },
        {
            title: 'تاریخ',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, {createdDate}) => (
                <>{toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'شماره پیگیری شاپرک',
            dataIndex: 'shaparakRefId',
            width: 200
        },
        {
            title: 'مبلغ نهایی',
            dataIndex: 'finalPrice',
            width: 200,
            render: (_, {finalPrice, type}) => {
                return <span style={{color: TransactionTypePriceColor[type]}}>{numberWithCommasToman(finalPrice)}</span>
            },
        },
        {
            title: 'کسر از کیف پول',
            dataIndex: 'payedFromWallet',
            width: 200,
            render: (_, {payedFromWallet, type}) => {
                return <span
                    style={{color: TransactionTypePriceColor[type]}}>{numberWithCommasToman(payedFromWallet)}</span>
            },
        },
        {
            title: 'پرداخت از درگاه',
            dataIndex: 'payedFromGateway',
            width: 200,
            render: (_, {payedFromGateway, type}) => {
                return <span
                    style={{color: TransactionTypePriceColor[type]}}>{numberWithCommasToman(payedFromGateway)}</span>
            },
        },
        {
            title: 'وضعیت',
            dataIndex: 'status',
            width: 200,
            render: (_, {status}) => (
                <Tag color={TransactionStatusColor[status]}>{TransactionStatusTitle[status]}</Tag>),
        },
        {
            title: 'نوع',
            dataIndex: 'type',
            width: 200,
            render: (_, {type}) => (
                <Tag color={TransactionTypeColor[type]}>{TransactionTypeTitle[type]}</Tag>
            ),
        },
        {
            width: 50,
            render: (_, record) => (
                record.status === TransactionStatus.WAIT_FOR_PAYMENT ? (isLoadingVerify === record.id ? <Spin/> :
                    <ReloadOutlined style={{cursor: 'pointer'}} onClick={event => {
                        setIsLoadingVerify(record.id);
                        PaymentService.paymentVerifyByAdmin(record.userId, record.authority).then(value => {
                            openSuccessNotificationByMessage("عملیات موفق");
                            loadData();
                        }).finally(() => setIsLoadingVerify(null));
                    }}/>) : null
            ),
        },
    ];

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>تراکنش ها</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Table className="admin-course-table" loading={dataLoading} scroll={{x: 750}} pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: params.pageSize,
                total: data?.totalElements,
                onChange: handleSelectPagination
            }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
        </Wrapper1>
    );
}