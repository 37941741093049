import React from "react";
import {Button, Card, Divider, Row, Typography} from "antd";
import {CheckCircleOutlined, LinkOutlined} from "@ant-design/icons";
import "antd/dist/reset.css";
import {Wrapper1} from "../../components/wrapper/Wrapper1";
import {SECOND_COLOR} from "../../constans/colors";
import {HeaderWrapper} from "../../components/header-wrapper";
import {Footer} from "../../components/new-footer";
import {useNavigate} from "react-router-dom";
import {USER_DASHBOARD_AFFILIATE_ROUTE} from "../../routes/route-path";
import {ImageSliderAffiliate} from "../../components/slider/ImageSliderAffiliate";
import {ImageSliderAffiliateContainer} from "../../components/slider/ImageSliderAffiliateContainer";
const {Paragraph} = Typography;

const LandingAffiliatePage = () => {

    const navigate = useNavigate();

    return (
        <>
            <HeaderWrapper/>
            <div className="container" style={{marginBottom:'20px'}}>
                <Wrapper1 style={{marginTop: "55px", padding: "15px 15px"}}>
                    <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>همکاری در فروش دانشگاهینو
                    </div>
                    <Divider style={{margin: '15px 0px'}}/>
                    <Paragraph style={{ textAlign: "justify", whiteSpace: "pre-line", fontSize: "16px", lineHeight: "1.8" }}>
                        <span style={{ color: "#ff4d4f", fontWeight: "bold" }}>سلام رفقا! </span>
                        <br />
                        <span style={{ color: "#595959" }}>می‌دونیم که شرایط اقتصادی این روزا برای همه، به خصوص دانشجوها، یکم سخت شده. اما خبر خوب اینه که دانشگاهینو یه راه جدید برای کسب درآمد براتون آورده! می‌پرسید چطوری؟ با همکاری در فروش دانشگاهینو!</span>
                        <br /><br />
                        <span style={{ color: "#1890ff", fontWeight: "bold" }}>همکاری در فروش دانشگاهینو چیه؟</span>
                        <br />
                        <span style={{ color: "#595959" }}>خیلی ساده است! شما با معرفی دوره‌های آموزشی دانشگاهینو به دوستاتون و هم‌کلاسی‌هاتون، می‌تونید درآمد کسب کنید. چطوری؟</span>
                        <br /><br />
                        <span style={{ color: "#52c41a", fontWeight: "bold" }}>📌 ثبت نام و دریافت لینک اختصاصی:</span>
                        <span style={{ color: "#595959" }}> اول از همه، تو سایت دانشگاهینو ثبت نام کنید. بعد از ورود به حسابتون، یه لینک اختصاصی برای همکاری در فروش دریافت می‌کنید.</span>
                        <br /><br />
                        <span style={{ color: "#faad14", fontWeight: "bold" }}>📢 اشتراک گذاری لینک:</span>
                        <span style={{ color: "#595959" }}> حالا وقتشه که لینک خودتون رو به اشتراک بذارید. می‌تونید این لینک رو تو شبکه‌های اجتماعی، گروه‌های دانشجویی یا هر جای دیگه‌ای که فکر می‌کنید مخاطب داره، منتشر کنید. برای اینکه کارتون راحت‌تر بشه، دانشگاهینو یه عالمه محتوای گرافیکی جذاب برای پست و استوری اینستاگرام آماده کرده که می‌تونید دانلود کنید و بدون دردسر، لینک خودتون رو تبلیغ کنید.</span>
                        <br /><br />
                        <span style={{ color: "#eb2f96", fontWeight: "bold" }}>💰 کسب درآمد:</span>
                        <span style={{ color: "#595959" }}> هر کسی که از طریق لینک شما وارد سایت دانشگاهینو بشه و تا ۳۰ روز بعدش حق اشتراک بخره، ۲۰ درصد از مبلغ پرداختیش به حساب شما واریز می‌شه. یعنی هر چی بیشتر معرفی کنید، درآمدتون هم بیشتر می‌شه!</span>
                        <br /><br />
                        <span style={{ color: "#722ed1", fontWeight: "bold" }}>🏦 دریافت وجه نقد یا خرید حق اشتراک:</span>
                        <span style={{ color: "#595959" }}> هر وقت هم که خواستید، می‌تونید مبلغی که تو حسابتون جمع شده رو به صورت نقد دریافت کنید یا باهاش برای خودتون حق اشتراک دانشگاهینو بخرید و از دوره‌های آموزشی باکیفیتش استفاده کنید.</span>
                        <br /><br />
                        <span style={{ color: "#13c2c2", fontWeight: "bold" }}>چرا همکاری در فروش دانشگاهینو؟</span>
                        <br /><br />
                        <span style={{ color: "#52c41a" }}><CheckCircleOutlined /> درآمدزایی آسان:</span>
                        <span style={{ color: "#595959" }}> بدون نیاز به سرمایه اولیه یا مهارت خاصی، می‌تونید درآمد کسب کنید.</span>
                        <br />
                        <span style={{ color: "#faad14" }}><CheckCircleOutlined /> انعطاف‌پذیری:</span>
                        <span style={{ color: "#595959" }}> هر وقت و هر جا که خواستید، می‌تونید لینک خودتون رو به اشتراک بذارید.</span>
                        <br />
                        <span style={{ color: "#1890ff" }}><CheckCircleOutlined /> محتوای آماده:</span>
                        <span style={{ color: "#595959" }}> دیگه لازم نیست وقتتون رو صرف طراحی گرافیکی کنید. دانشگاهینو همه چیز رو براتون آماده کرده.</span>
                        <br />
                        <span style={{ color: "#eb2f96" }}><CheckCircleOutlined /> آموزش باکیفیت:</span>
                        <span style={{ color: "#595959" }}> با معرفی دوره‌های دانشگاهینو، به دوستاتون کمک می‌کنید که راحت تر دروس دانشگاهی رو پاس کنند.</span>
                        <br /><br />
                        <span style={{ color: "#722ed1", fontWeight: "bold" }}>پس منتظر چی هستید؟ همین الان تو دانشگاهینو ثبت نام کنید و لینک اختصاصی خودتون رو دریافت کنید و شروع به کسب درآمد کنید!</span>
                    </Paragraph>

                    <Button type="primary" icon={<LinkOutlined/>} size="large" onClick={() => navigate(USER_DASHBOARD_AFFILIATE_ROUTE)}
                            style={{display: "block", margin: "20px auto"}}>
                        دریافت لینک همکاری در فروش
                    </Button>
                </Wrapper1>
            </div>
            <div className="container">
                <ImageSliderAffiliateContainer/>
            </div>
            <Footer/>
        </>
    );
};

export default LandingAffiliatePage;
