import {notification} from 'antd';
import {LOGIN_WITH_REDIRECT_ROUTE} from "../routes/route-path";
import StorageService from "./StorageService";
import {AxiosError} from "axios";
import TokenService from "./TokenService";
import moment from "jalali-moment";
import {Counter, ModeOfOperation} from 'aes-js';
import {Buffer} from 'buffer';

interface Errors {
    field: string;
    message: string;
}

export interface Pageable {
    pageNumber: number;
}

export interface Page<T> {
    content: T[];
    totalElements: number;
    number: number;
    size: number;
    last:boolean;
    pageable:Pageable;
}

export interface PageQueryParams {
    pageNumber: number;
    pageSize: number;
}

export function handleError(error: any) {
    if (error.response.status === 401) {
        StorageService.removeItem("token");
        StorageService.removeItem("roles");
        StorageService.removeItem("profile");
        document.location.href = LOGIN_WITH_REDIRECT_ROUTE;
    } else if (error.response.status >= 400 && error.response.status < 500) {
        if (error.response.data.message) openErrorNotification(error.response.data.message);
        if (error.response.data.errors) {
            error.response.data.errors.forEach((elem: Errors) => {
                openErrorNotification(elem.message)
            })
        }
    } else if (error.response.status >= 500) {
        openErrorNotification("خطایی سمت سرور رخ داده است.")
    }
}

export const openErrorNotification = (message: string, duration?:number) => {
    notification.error({
        message: 'خطا',
        description: message,
        placement: "top",
        className:'notification-messages',
        duration: duration || duration === 0 ? duration : 10
    });
}
export const openSuccessNotification = () => {
    notification.success({
        message: '',
        description: 'عملیات با موفقیت انجام شد.',
        placement: "top",
        duration: 5
    });
}

export const numberWithCommas = (x: any) => {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const numberWithCommasToman = (x: any) => {
     let number = numberWithCommas(x);
     return number ? number +  ' تومان' : number;
}

export const numberWithCommasTomanAndIncludeZero = (x: any) => {
    let number = numberWithCommas(x);
    return number || number === 0 ? number +  ' تومان' : number;
}

export const openSuccessNotificationByMessage = (msg: string, duration?:number) => {
    notification.success({
        message: '',
        description: msg,
        placement: "top",
        duration: duration || duration === 0 ? duration : 10
    });
}

export async function genericApiCall<T>(api: Function): Promise<T> {
    try {
        return await api();
    } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 401) {
            await TokenService.updateToken();
            return await api();
        }
        handleError(error);
        throw error;
    }
}

export function toPersianDateTime(input: Date): string | null {
    if (input) return moment(input, 'YYYY-MM-DDTHH:mm:ss').locale('fa').format('HH:mm:ss YYYY/MM/DD');
    return null;
}

export function toPersianDateTime2(input: Date): string | null {
    if (input) return moment(input, 'YYYY-MM-DDTHH:mm:ss').locale('fa').format('YYYY/MM/DD HH:mm');
    return null;
}

export function toPersianDate(input: Date): string | null {
    if (input) return moment(input, 'YYYY-MM-DDTHH:mm:ss').locale('fa').format('YYYY/MM/DD');
    return null;
}

const keyBytes = Buffer.from('my_super_secret_', 'utf-8');

export function encrypt(input: string): string{
    const bytes = Buffer.from(input, 'utf-8');
    const aesEcb = new ModeOfOperation.ctr(keyBytes, new Counter(5));
    const encryptedBytes = aesEcb.encrypt(bytes);
    const buffer = Buffer.from(encryptedBytes.buffer);
    return buffer.toString('base64');
}

export function decrypt(input: string): string | null {
    const encryptedBytes = Buffer.from(input, 'base64');
    const aesEcb = new ModeOfOperation.ecb(keyBytes);
    const decryptedBytes = aesEcb.decrypt(encryptedBytes);
    const paddingLength = decryptedBytes[decryptedBytes.length - 1];
    const paddedData = decryptedBytes.subarray(0, decryptedBytes.length - paddingLength);
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(paddedData);
}

export function toEnglishNumber(input: string): string {
    const pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    const en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    let cache = input;
    for (let i = 0; i < 10; i++) {
        let regex_fa = new RegExp(pn[i], 'g');
        cache = cache.replace(regex_fa, en[i]);
    }
    return cache;
}

export function getLocalDateTime(date : Date) : string | undefined{
    if (!date) return undefined;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    // Format date string
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

// export async function decryptorUint8Array(input: Uint8Array): Promise<Uint8Array> {
//     return new Promise(async (resolve, reject) => {
//
//         const blockSize = 16; // Block size in bytes (e.g., 16 bytes for AES)
//         const remainder = input.length % blockSize;
//         if (remainder !== 0) {
//             // Pad the ciphertext with zeros to make it a multiple of the block size
//             const paddingLength = blockSize - remainder;
//             const paddedCiphertext = new Uint8Array(input.length + paddingLength);
//             paddedCiphertext.set(input);
//             const secretKey = 'my_super_secret_';
//             const encryptedBytes = await Buffer.from(paddedCiphertext);
//             const keyBytes = await Buffer.from(secretKey, 'utf-8');
//             const aesEcb = await new ModeOfOperation.ecb(keyBytes);
//             const decryptedBytes = await aesEcb.decrypt(encryptedBytes);
//             resolve(await decryptedBytes.subarray(0, decryptedBytes.length - paddingLength));
//
//         } else {
//             const secretKey = 'my_super_secret_';
//             const encryptedBytes = await Buffer.from(input);
//             const keyBytes = await Buffer.from(secretKey, 'utf-8');
//             const aesEcb = await new ModeOfOperation.ecb(keyBytes);
//             resolve(await aesEcb.decrypt(encryptedBytes));
//         }
//         // const decryptedBytes = await aesEcb.decrypt(encryptedBytes);
//         // const paddingLength = await decryptedBytes[decryptedBytes.length - 1];
//         // resolve(await decryptedBytes.subarray(0, decryptedBytes.length - paddingLength));
//     });
// }

// export async function decryptorUint8Array2(input: Uint8Array): Promise<Uint8Array> {
// return await new Promise(async resolve => {
//     const blockSize = 16; // Block size in bytes (e.g., 16 bytes for AES)
//     const remainder = input.length % blockSize;
//     if (remainder !== 0) {
//         console.log("1")
//         // Pad the ciphertext with zeros to make it a multiple of the block size
//         const paddingLength = blockSize - remainder;
//         const paddedCiphertext = new Uint8Array(input.length + paddingLength);
//         paddedCiphertext.set(input);
//         const encryptedBytes = Buffer.from(paddedCiphertext);
//         const aesEcb = new ModeOfOperation.ecb(keyBytes);
//         let decryptedBytes = aesEcb.decrypt(encryptedBytes);
//         decryptedBytes = decryptedBytes.subarray(0, decryptedBytes.length - paddingLength);
//         resolve(decryptedBytes);
//
//     } else {
//         console.log("2")
//         const encryptedBytes = Buffer.from(input);
//         const aesEcb = new ModeOfOperation.ecb(keyBytes);
//         const decryptedBytes = aesEcb.decrypt(encryptedBytes);
//         resolve(decryptedBytes);
//     }
// })
//
// }

// const fff = async () => {
//     await new Promise(r => setTimeout(r, 2000));
//
//     const mediaSource = new MediaSource();
//     if (videoRef.current){
//         console.log("src added")
//         videoRef.current.src = window.URL.createObjectURL(mediaSource);
//     }
//     console.log("be")
//     // await new Promise(r => setTimeout(r, 5000));
//     console.log("af")
//     mediaSource.addEventListener('sourceclose', function(e) {console.log("SOURCE CLOSED");}, false);
//     mediaSource.addEventListener('sourceopen', async () => {
//         const sourceBuffer = mediaSource.addSourceBuffer('video/mp4; codecs="avc1.42E01E, mp4a.40.2"');
//         sourceBuffer.addEventListener('error', ev => console.log(ev));
//         const response = await fetch(baseUrl + "/public/v1/files/download/a69f6f14-a26e-4b00-b2bb-5a3b02ef33f4/frag_bunny.mp4", {
//             headers: {
//                 'Content-Type': 'application/octet-stream',
//                 'Range': 'bytes=0-'
//             }
//         });
//
//         const body = response.body
//
//         const reader = body?.getReader()
//         let streamNotDone = true;
//         let que: Uint8Array = new Uint8Array();
//
//         while (streamNotDone) {
//             if (reader) {
//
//                 const {value, done} = await reader.read();
//
//                 if (done) {
//                     console.log("done")
//                     // const blocks = await splitIntoBlocks(que, 8001);
//                     // for (let i = 0; i < blocks.length; i++) {
//                     //
//                     //     await new Promise( async (resolve, reject) => {
//                     //         const decryptedValue = await decryptorUint8Array2(blocks[i]);
//                     //         sourceBuffer.appendBuffer(decryptedValue);
//                     //         sourceBuffer.onupdateend = (() => {
//                     //             console.log("end")
//                     //             resolve(true);
//                     //         })
//                     //     })
//                     //
//                     //
//                     // }
//                     streamNotDone = false;
//                     break;
//                 }
//
//                 // que = await concatArray(que, value);
//                 await new Promise( async (resolve, reject) => {
//                     console.log(value.length)
//                     const decryptedValue = await decryptorUint8Array2(value);
//                     console.log(decryptedValue.length)
//                     console.log("is appending")
//                     sourceBuffer.appendBuffer(decryptedValue);
//                     console.log("end appending")
//                     sourceBuffer.onupdateend = (async () => {
//                         console.log("end")
//                         await new Promise(r => setTimeout(r, 2000));
//                         // if(videoRef.current) videoRef.current?.play()
//                         resolve(true);
//                     })
//                 })
//             }
//         }
//     });
// }
