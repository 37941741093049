import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, Modal, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import {openErrorNotification} from "../../../../services/UtilFunctions";
import PlanService from "../../../../services/PlanService";

const {Option} = Select;

interface Props {
    show: boolean

    setShow(b: boolean): void
}

export const PlanCreate = ({show, setShow}: Props) => {

    const [form] = Form.useForm()
    const [file, setFile] = useState<RcFile | null>();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        if (!file) {
            openErrorNotification("لطفا کاور را انتخاب کنید.");
            return;
        }
        values = {...values, cover: file}
        setLoading(true);
        PlanService.addPlan(values).then(response => {
            form.resetFields();
            setShow(false);
            setLoading(false);
        }).catch(reason => {
            setLoading(false);
        });
    }

    useEffect(() => {
    }, []);

    const justAllowNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, e.target.value.replace(/\D/g, ''))
    };

    return (
        <Modal
            title="افزودن اشتراک جدید"
            style={{marginTop:'10px', marginBottom:'10px'}}
            centered
            open={show}
            onOk={() => {
            }}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={form}
                name="basic"
                wrapperCol={{span: 16}}
                style={{maxWidth: 600}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
            >
                <Form.Item
                    name="title"
                    label='عنوان'
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                >
                    <Input placeholder="عنوان"/>
                </Form.Item>
                <Form.Item rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                    <Upload
                        accept=".jpeg, .jpg, .png"
                        maxCount={1}
                        beforeUpload={(file) => {
                            setFile(file);
                            return false;
                        }}
                        onRemove={(file) => {
                            setFile(null);
                        }}
                    >
                        <Button icon={<UploadOutlined/>}>انتخاب عکس کاور</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    name="description"
                    label='توضیحات'
                >
                    <Input placeholder="توضیحات"/>
                </Form.Item>
                <Form.Item
                    name="price"
                    label='قیمت به تومان'
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                >
                    <Input name="price" onChange={justAllowNumbers} placeholder="قیمت به تومان"/>
                </Form.Item>
                <Form.Item
                    name="discountPercentage"
                    label='درصد تخفیف'
                >
                    <InputNumber style={{width: "100%"}} min={0} max={100} placeholder="درصد تخفیف"/>
                </Form.Item>
                <Form.Item
                    name="days"
                    label='تعداد روز'
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                >
                    <Input name="days" onChange={justAllowNumbers} placeholder="تعداد روز"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        افزودن
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}