import React, {useEffect, useState} from "react";
import {Button, Form, Input, Modal, Select} from "antd";
import CategoryService, {Category} from "../../../../services/CategoryService";
import CourseService from "../../../../services/CourseService";
import TopCoursesService from "../../../../services/TopCoursesService";
import {openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";

const { Option } = Select;

interface Props {
    show:boolean
    setShow(b : boolean):void
}

interface OptionType {
    value?:number;
    label?:string;
}

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (value: string, callback: Function) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    const fake = () => {
        CourseService.searchCourses({pageNumber: 0, pageSize: 20, title:value}).then(page => {
            callback(
                page.content.map(course => {
                    return {
                        value:course.id,
                        label:course.title + " " + (course.teacher ? (course.teacher.firstName + course.teacher.lastName) : "")
                    }
                })
            );
        });
    };
    if (value) {
        timeout = setTimeout(fake, 300);
    } else {
        callback([]);
    }
};

export const TopCoursesCreate =({show, setShow} : Props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const [courses, setCourses] = useState<OptionType[]>([]);
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 20,
    });

    const [value, setValue] = useState<string>();

    const handleSearch = (newValue: string) => {
        fetch(newValue, setCourses);
    };

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        loadCategories();
    }, [])

    const loadCategories = () => {
        CategoryService.getCategoriesForAdmin().then(categories => {
            setCategories(categories);
        });
    }

    const onFinish = (values: any) => {
        console.log(values)
        setLoading(true);
        TopCoursesService.add(values).then(response => {
            form.resetFields();
            openSuccessNotificationByMessage("دوره برتر با موفقیت اضافه شد.")
            setShow(false);
            setLoading(false);
        }).catch(reason => {
            setLoading(false);
        });
    }

    const justAllowNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, e.target.value.replace(/\D/g,''))
    };

    return(
        <Modal
            title="افزودن دوره برتر"
            style={{marginTop:'10px', marginBottom:'10px'}}
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={form}
                name="basic"
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
            >

                <Form.Item name="categoryId" label='دسته بندی' rules={[{ required: true, message: 'این فیلد الزامی است.'}]}>
                    <Select
                        placeholder="دسته بندی"
                    >
                        {
                            categories.map(category => {
                                return (<Option value={category.id}>{category.title}</Option>)
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item name="courseId" label='انتخاب دوره' rules={[{ required: true, message: 'این فیلد الزامی است.'}]}>
                    <Select
                        showSearch
                        value={value}
                        placeholder={"انتخاب دوره"}
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={handleSearch}
                        onChange={handleChange}
                        notFoundContent={null}
                        options={(courses || []).map((d) => ({
                            value: d.value,
                            label: d.label,
                        }))}
                    />
                </Form.Item>

                <Form.Item name="priorityNumber" label='الویت نمایش دوره' rules={[{ required: true, message: 'این فیلد الزامی است.'}]}>
                    <Input name="priorityNumber" onChange={justAllowNumbers} placeholder="الویت نمایش دوره"/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        افزودن
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}