import React from 'react';
import {
    ABOUT_US_ROUTE,
    ADMIN_DASHBOARD_ROUTE,
    BUY_PLAN_ROUTE,
    CATEGORY_COURSES_ROUTE,
    CF_ROUTE,
    CONTACT_US_ROUTE,
    COOPERATION_FORM_ROUTE,
    COURSE_ROUTE,
    FAQ_ROUTE,
    FORGET_PASSWORD_ROUTE,
    HOME_PAGE_ROUTE, LANDING_AFFILIATE_ROUTE,
    LOGIN_ROUTE,
    PAYMENT_VERIFY_ROUTE,
    REGISTER_ROUTE,
    SEARCH_ROUTE,
    TEACHER_COURSES_ROUTE,
    TEACHER_DASHBOARD_ROUTE,
    TEACHERS_ROUTE,
    USER_DASHBOARD_ROUTE
} from './route-path';
import {HomePage} from '../pages/home';
import {RegisterPage} from "../pages/auth/register";
import {LoginPage} from "../pages/auth/login";
import {CoursePage} from "../pages/course";
import {SearchResultPage} from "../pages/search-result";
import {AdminDashboardPage} from "../pages/admin-dashboard";
import {VerifyPage} from "../pages/payment/verify";
import {ForgetPasswordPage} from "../pages/auth/forget-password";
import {TeacherDashboardPage} from "../pages/teacher-dashboard";
import {TeacherCoursesPage} from "../pages/teacher-courses";
import {AboutUsPage} from "../pages/about-us";
import {ContactUsPage} from "../pages/contact-us";
import {TeachersPage} from "../pages/teacher";
import {UserDashboardPage} from "../pages/user-dashboard";
import {CooperationFormPage} from "../pages/cooperation-form";
import {BuyPlanPage} from "../pages/buy-plan";
import {CategoryCoursesPage} from "../pages/category-courses";
import {FAQPage} from "../pages/faq";
import {CFPage} from "../pages/cooperation-form/CFPage";
import LandingAffiliatePage from "../pages/landing-affiliate";

export const PAGE_ROUTES = [
    {
        id: 1,
        isPrivate: false,
        deactivate: false,
        path: HOME_PAGE_ROUTE,
        element: <HomePage/>
    },
    {
        id: 3,
        isPrivate: false,
        deactivate: false,
        path: REGISTER_ROUTE,
        element: <RegisterPage/>
    },
    {
        id: 4,
        isPrivate: false,
        deactivate: false,
        path: LOGIN_ROUTE,
        element: <LoginPage/>
    },
    {
        id: 7,
        isPrivate: false,
        deactivate: false,
        path: COURSE_ROUTE,
        element: <CoursePage/>
    },
    {
        id: 8,
        isPrivate: false,
        deactivate: false,
        path: SEARCH_ROUTE,
        element: <SearchResultPage/>
    },
    {
        id: 9,
        isPrivate: false,
        deactivate: false,
        path: ADMIN_DASHBOARD_ROUTE,
        element: <AdminDashboardPage/>
    },
    {
        id: 10,
        isPrivate: false,
        deactivate: false,
        path: PAYMENT_VERIFY_ROUTE,
        element: <VerifyPage/>
    },
    {
        id: 12,
        isPrivate: false,
        deactivate: false,
        path: FORGET_PASSWORD_ROUTE,
        element: <ForgetPasswordPage/>
    },
    {
        id: 13,
        isPrivate: false,
        deactivate: false,
        path: TEACHER_DASHBOARD_ROUTE,
        element: <TeacherDashboardPage/>
    },
    {
        id: 15,
        isPrivate: false,
        deactivate: false,
        path: TEACHER_COURSES_ROUTE,
        element: <TeacherCoursesPage/>
    },
    {
        id: 16,
        isPrivate: false,
        deactivate: false,
        path: ABOUT_US_ROUTE,
        element: <AboutUsPage/>
    },
    {
        id: 17,
        isPrivate: false,
        deactivate: false,
        path: CONTACT_US_ROUTE,
        element: <ContactUsPage/>
    },
    {
        id: 18,
        isPrivate: false,
        deactivate: false,
        path: TEACHERS_ROUTE,
        element: <TeachersPage/>
    },
    {
        id: 19,
        isPrivate: false,
        deactivate: false,
        path: USER_DASHBOARD_ROUTE + '*',
        element: <UserDashboardPage/>
    },
    {
        id: 20,
        isPrivate: false,
        deactivate: false,
        path: COOPERATION_FORM_ROUTE,
        element: <CooperationFormPage/>
    },
    {
        id: 21,
        isPrivate: false,
        deactivate: false,
        path: BUY_PLAN_ROUTE,
        element: <BuyPlanPage/>
    },
    {
        id: 22,
        isPrivate: false,
        deactivate: false,
        path: CATEGORY_COURSES_ROUTE,
        element: <CategoryCoursesPage/>
    },
    {
        id: 23,
        isPrivate: false,
        deactivate: false,
        path: FAQ_ROUTE,
        element: <FAQPage/>
    },
    {
        id: 24,
        isPrivate: false,
        deactivate: false,
        path: CF_ROUTE,
        element: <CFPage/>
    },
    {
        id: 25,
        isPrivate: false,
        deactivate: false,
        path: LANDING_AFFILIATE_ROUTE,
        element: <LandingAffiliatePage/>
    },
];
