import React from "react";
import './contactUs.css'

const color = "#2D9CDB";

export const ContactUs: React.FC =() => {

    return(
        <div style={{width:"140px", padding:"4px"}}>
            <span style={{fontSize:"16px", color:color, fontWeight:"400", verticalAlign:"middle"}}>09037681604</span>
            <svg style={{verticalAlign:"middle", float:"left"}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.05599 2.41799L8.22299 2.06699C8.86228 1.87422 9.54987 1.92062 10.1575 2.19755C10.765 2.47448 11.2511 2.96302 11.525 3.57199L12.427 5.57799C12.6625 6.1018 12.728 6.68617 12.6145 7.24913C12.501 7.81209 12.2141 8.32538 11.794 8.71699L10.3 10.11C10.2563 10.1507 10.2286 10.2057 10.222 10.265C10.178 10.662 10.447 11.435 11.067 12.51C11.518 13.291 11.927 13.84 12.274 14.147C12.516 14.362 12.649 14.408 12.706 14.392L14.716 13.777C15.2649 13.6091 15.8526 13.6172 16.3967 13.8001C16.9408 13.983 17.414 14.3316 17.75 14.797L19.031 16.573C19.4205 17.1131 19.6009 17.7762 19.5385 18.4392C19.4762 19.1021 19.1754 19.72 18.692 20.178L17.806 21.018C17.3358 21.4633 16.7582 21.7792 16.1296 21.935C15.501 22.0908 14.8428 22.0812 14.219 21.907C11.465 21.138 8.99599 18.814 6.78399 14.983C4.56899 11.147 3.79199 7.84299 4.50799 5.06999C4.66916 4.44633 4.98827 3.8747 5.43456 3.41019C5.88085 2.94569 6.43927 2.60398 7.05599 2.41799ZM7.48899 3.85499C7.11906 3.96645 6.78407 4.17128 6.51627 4.44976C6.24847 4.72824 6.05689 5.07099 5.95999 5.44499C5.35799 7.77699 6.04699 10.706 8.08299 14.233C10.116 17.755 12.305 19.815 14.623 20.463C14.9971 20.5672 15.3919 20.5728 15.7688 20.4792C16.1457 20.3856 16.492 20.1961 16.774 19.929L17.661 19.089C17.8808 18.8808 18.0176 18.5999 18.0459 18.2984C18.0742 17.997 17.9922 17.6955 17.815 17.45L16.535 15.675C16.3822 15.4632 16.167 15.3046 15.9195 15.2213C15.672 15.1381 15.4047 15.1345 15.155 15.211L13.14 15.828C11.97 16.176 10.908 15.235 9.76799 13.26C8.99999 11.93 8.64199 10.9 8.73099 10.099C8.77799 9.68299 8.97099 9.29899 9.27699 9.01299L10.771 7.61999C10.962 7.44201 11.0925 7.20868 11.1442 6.95274C11.1958 6.69681 11.166 6.43113 11.059 6.19299L10.157 4.18699C10.0325 3.9104 9.81171 3.68851 9.53573 3.56266C9.25976 3.43682 8.94744 3.41561 8.65699 3.50299L7.48899 3.85499Z" fill={color}/>
            </svg>
        </div>

);

}

