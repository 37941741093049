import React, {useState} from "react";
import {Button, Flex, Form, Image, Modal, Select, Upload} from "antd";
import UserService, {User} from "../../../../services/UserService";
import {UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import {openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";
const {Option} = Select;

interface Props {
    show: boolean;

    setShow(b: boolean): void;

    user: User;
}

export const UserUpdate = ({show, setShow, user}: Props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<RcFile | null>();

    const onFinish = (values: any) => {
        setLoading(true);
        values = {...values, photo: file}
        UserService.selfUpdateUser(values).then(response => {
            form.resetFields();
            setFile(null);
            setShow(false);
            setLoading(false);
            openSuccessNotificationByMessage("اطلاعات شما با موفقیت ویرایش شد.", 5);
        }).catch(reason => {
            setLoading(false);
        });
    }

    return (
        <Modal
            title="ویرایش"
            centered
            open={show}
            onOk={() => {
            }}
            onCancel={() => {
                form.resetFields();
                setFile(null);
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={form}
                name="basic"
                labelCol={{span: 0}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 600}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item>
                    <Upload
                        accept=".jpeg, .jpg, .png"
                        listType="picture"
                        maxCount={1}
                        beforeUpload={(file) => {
                            setFile(file);
                            return false;
                        }}
                        onRemove={(file) => {
                            setFile(null);
                        }}
                    >
                        <Button icon={<UploadOutlined/>}>انتخاب عکس پروفایل</Button>
                    </Upload>
                </Form.Item>
                <Image
                    style={{marginTop: '15px', marginBottom: '30px'}}
                    width={100}
                    src={baseNonVideoUrl + user?.photoUrl}
                />

                <Flex justify='end'>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            ویرایش
                        </Button>
                    </Form.Item>
                </Flex>

            </Form>
        </Modal>
    );

}