import React, {useState} from "react";
import {Navigation} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {LazyImage} from "../common/LazyImage";

const importImage = (filePath : string) => require(`../../assets/images/affiliate/${filePath}`);

interface Props {
    files : ImagesProperty[];
}

export interface ImagesProperty {
    orgPath : string;
    previewPath : string;
}

export const ImageSliderAffiliate = ({files}: Props) => {
    const [swiperRef, setSwiperRef] = useState<any>(null);

    return(
        <Swiper
            breakpoints={{
                100: {
                    slidesPerView: 1.2,
                    spaceBetween: 8,
                },
                320: {
                    slidesPerView: 1.5,
                    spaceBetween: 5,
                },
                640: {
                    slidesPerView: 2.5,
                    spaceBetween: 5,
                },
                768: {
                    slidesPerView: 3.2,
                    spaceBetween: 5,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 5,
                },
            }}
            modules={[Navigation]}
            className="mySwiper"
            onSwiper={setSwiperRef}
            navigation={true}
        >
            {
                files && files.map(f => {
                    return (
                        <SwiperSlide>
                            <a href={importImage(f.orgPath)} download>
                                <LazyImage style={{width:'200px'}}
                                           alt='تصاویر استوری - پست'
                                           src={importImage(f.previewPath)} />
                            </a>
                        </SwiperSlide>
                    );
                })
            }
        </Swiper>
    );

}