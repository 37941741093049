import React, {useEffect, useState} from "react";
import {Button, Divider, Form, Image, Input, Modal, Select, Upload} from "antd";
import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import CourseService, {
    Chapter,
    Course, Lesson,
    LessonAccessLevel,
    LessonAccessLevelTitle
} from "../../../../services/CourseService";
import {openErrorNotification, openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";
import TextArea from "antd/es/input/TextArea";
import ChapterService from "../../../../services/ChapterService";
const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";
const {Option} = Select;

interface Props {
    show: boolean;
    setShow(b: boolean): void;
    chapter?: Chapter | undefined;
}

export const ChapterUpdate = ({show, setShow, chapter}: Props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {

    }, []);

    const onFinish = (values: any) => {
        if (chapter){
            setLoading(true);
            ChapterService.update(chapter.id, values).then(response => {
                form.resetFields();
                openSuccessNotificationByMessage("فصل با موفقیت ویرایش شد.");
                setShow(false);
                setLoading(false);
            }).catch(reason => {
                setLoading(false);
            })
        }
    }

    const justAllowNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, e.target.value.replace(/\D/g,''))
    };

    return (
        <Modal
            title="ویرایش فصل"
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
            bodyStyle={{maxHeight:"700px", overflow: 'auto'}}
        >
            <div>
            <Form
                form={form}
                name="basic"
                wrapperCol={{span: 24}}
                style={{width: '100%'}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
            >
                <Form.Item name="title" label='عنوان فصل' initialValue={chapter?.title}><Input placeholder="عنوان فصل"/></Form.Item>
                <Form.Item name="number" label='شماره فصل' initialValue={chapter?.number}><Input name="number" onChange={justAllowNumbers} placeholder="شماره فصل"/></Form.Item>
                <Form.Item><Button type="primary" htmlType="submit" loading={loading}>ویرایش</Button></Form.Item>
            </Form>
            </div>
        </Modal>
    );

}