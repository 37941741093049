import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, Modal, Select} from "antd";
import PlanService, {Plan} from "../../../../services/PlanService";
import DiscountCodeService from "../../../../services/DiscountCodeService";

const {Option} = Select;

interface Props {
    show: boolean

    setShow(b: boolean): void
}

export const DiscountCodeExcelCreate = ({show, setShow}: Props) => {

    const [form] = Form.useForm()
    const [plans, setPlans] = useState<Plan[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        if (values.planIds && values.planIds.length === 0) {
            values = {...values, planIds: null}
        }
        setLoading(true);
        DiscountCodeService.createExcel(values).then(response => {
            const url = URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'discountCodes.xlsx');
            document.body.appendChild(link);
            link.click()

            form.resetFields();
            setShow(false);
            setLoading(false);
        }).catch(reason => {
            setLoading(false);
        });
    }

    useEffect(() => {
        PlanService.getPlansForAdmin({pageNumber: 0, pageSize: 1000}).then(page => {
            setPlans(page.content)
        });
    }, []);

    const justAllowNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, e.target.value.replace(/\D/g, ''))
    };

    return (
        <Modal
            title="افزودن کد تخفیف جدید(excel)"
            style={{marginTop:'10px', marginBottom:'10px'}}
            centered
            open={show}
            onOk={() => {
            }}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
            width={800}
        >
            <Form
                form={form}
                name="basic"
                wrapperCol={{span: 16}}
                style={{maxWidth: 800}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
            >
                <Form.Item name="planIds" label="اشتراک">
                    <Select
                        placeholder="اشتراک"
                        allowClear={true}
                        mode="multiple"
                    >
                        {
                            plans.map(plan => {
                                return (<Option value={plan.id}>{plan.title}</Option>)
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    name="discountPercentage"
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                    label="درصد تخفیف"
                >
                    <InputNumber style={{width: "100%"}} min={0} max={100} placeholder="درصد تخفیف"/>
                </Form.Item>
                <Form.Item
                    name="days"
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                    label="تعداد روز"
                >
                    <Input name="days" onChange={justAllowNumbers} placeholder="تعداد روز"/>
                </Form.Item>

                <Form.Item
                    name="maxUsedCount"
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                    label="حداکثر تعداد استفاده"
                >
                    <Input name="maxUsedCount" onChange={justAllowNumbers} placeholder="حداکثر تعداد استفاده"/>
                </Form.Item>

                <Form.Item
                    name="numberOfDiscountCode"
                    rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                    label="تعداد کد های تخفیف"
                >
                    <InputNumber style={{width: "100%"}} min={0} placeholder="تعداد کد های تخفیف"/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        افزودن
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}