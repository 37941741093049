import React, {useState} from "react";
import {Button, Form, Input, Modal, Upload} from "antd";
import CategoryService from "../../../../services/CategoryService";
import {UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import {openErrorNotification} from "../../../../services/UtilFunctions";

interface Props {
    show:boolean
    setShow(b : boolean):void
}

export const CategoryCreate =({show, setShow} : Props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [ file, setFile ] = useState<RcFile | null>();

    const onFinish = (values: any) => {
        if (!file){
            openErrorNotification("لطفا کاور را انتخاب کنید.");
            return;
        }
        values = {...values, cover:file}
        setLoading(true);
        CategoryService.addCategory(values).then(response => {
            form.resetFields();
            setShow(false);
            setLoading(false);
        }).catch(reason => {
            setLoading(false);
        });
    }

    return(
        <Modal
            title="افزودن دسته بندی جدید"
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={form}
                name="basic"
                wrapperCol={{ span: 24 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
            >
                <Form.Item
                    name="title"
                    label='عنوان'
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="عنوان"/>
                </Form.Item>
                <Form.Item rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                    <Upload
                        accept=".jpeg, .jpg, .png"
                        maxCount={1}
                        beforeUpload={(file) => {
                            setFile(file);
                            return false;
                        }}
                        onRemove={(file) => {
                            setFile(null);
                        }}
                    >
                        <Button icon={<UploadOutlined />}>انتخاب عکس کاور</Button>
                    </Upload>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        افزودن
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}