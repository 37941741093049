import React, {useEffect, useState} from 'react';
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {FIFTH_COLOR, THIRD_COLOR} from "../../../../constans/colors";
import UserService, {User} from "../../../../services/UserService";
import {Avatar, Button, Col, Flex, Form, Input, Progress, Row, Select, Space, Spin} from "antd";
import {openSuccessNotification, toEnglishNumber} from "../../../../services/UtilFunctions";
import {Degree, DegreeTitle} from "../../../../services/EducationService";
import {
    FieldOfStudy, FieldOfStudyTitle,
    Gender,
    GenderTitle,
    IntroductionMethod,
    IntroductionMethodTitle,
    ScientificAssociationRole, ScientificAssociationRoleTitle
} from "../../../../constans/enums";
import {LoadingOutlined} from "@ant-design/icons";

const {Option} = Select;
const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

export const UserProfileContainer = () => {

    const [user, setUser] = useState<User>();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadUser();
    }, [])

    const loadUser = () => {
        UserService.getSelfProfile().then(response => {
            setUser(response);
            form.setFieldValue("firstName", response.firstName);
            form.setFieldValue("lastName", response.lastName);
            form.setFieldValue("university", response.university);
            form.setFieldValue("degree", response.degree);
            form.setFieldValue("gender", response.gender);
            form.setFieldValue("introductionMethod", response.introductionMethod);
            form.setFieldValue("scientificAssociationRole", response.scientificAssociationRole);
            form.setFieldValue("fieldOfStudy", response.fieldOfStudy);
        });
    }

    const justAllowEnglishNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        let str = toEnglishNumber(e.target.value);
        form.setFieldValue(e.target.name, str.replace(/\D/g, ''))
    };

    const submitChanges = (values: any) => {
        setLoading(true);
        UserService.completeProfile(values).then(value => {
            loadUser()
            openSuccessNotification()
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Wrapper1 style={{margin: "2rem", marginTop: "0px", padding: "20px", color: THIRD_COLOR}}>
            <div>
                <Row align="middle">
                    <Col span={4}>
                        <Avatar size={55} style={{marginBottom: "20px"}}/>
                    </Col>
                    <Col span={20} style={{textAlign:"left"}}>
                        {/*<span style={{marginLeft:"15px", fontSize:"1rem"}}>درصد تکمیل پروفایل</span>*/}
                        <Progress size={55} type="circle"  percent={user?.completeProfileProgress} />
                    </Col>
                </Row>
                {user ? (
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={submitChanges}
                    >
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="firstName"
                                    rules={[{required: true, message: 'لطفا نام خود را وارد کنید!'}]}
                                    label="نام"
                                    initialValue={user?.firstName}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="lastName"
                                    rules={[{required: true, message: 'لطقا نام خانوادگی خود را وارد کنید!'}]}
                                    label="نام خانوادگی"
                                    initialValue={user?.lastName}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="university"
                                    label="نام دانشگاه"
                                    initialValue={user?.university}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="degree"
                                    label="مدرک"
                                    initialValue={user?.degree}
                                >
                                    <Select allowClear>
                                        <Option value={Degree.ASSOCIATE}>{DegreeTitle[Degree.ASSOCIATE]}</Option>
                                        <Option value={Degree.BACHELOR}>{DegreeTitle[Degree.BACHELOR]}</Option>
                                        <Option
                                            value={Degree.MASTER_STUDENT}>{DegreeTitle[Degree.MASTER_STUDENT]}</Option>
                                        <Option value={Degree.MASTER}>{DegreeTitle[Degree.MASTER]}</Option>
                                        <Option value={Degree.DOCTORAL}>{DegreeTitle[Degree.DOCTORAL]}</Option>
                                        <Option
                                            value={Degree.DOCTORAL_STUDENT}>{DegreeTitle[Degree.DOCTORAL_STUDENT]}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="fieldOfStudy"
                                    label="رشته تحصیلی"
                                    initialValue={user?.fieldOfStudy}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="gender"
                                    label="جنسیت"
                                    initialValue={user?.gender}
                                >
                                    <Select allowClear>
                                        <Option value={Gender.MALE}>{GenderTitle[Gender.MALE]}</Option>
                                        <Option value={Gender.FEMALE}>{GenderTitle[Gender.FEMALE]}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="introductionMethod"
                                    label="نحوه آشنایی با دانشگاهینو"
                                    initialValue={user?.introductionMethod}
                                >
                                    <Select allowClear>
                                        <Option
                                            value={IntroductionMethod.SOCIAL_MEDIA}>{IntroductionMethodTitle[IntroductionMethod.SOCIAL_MEDIA]}</Option>
                                        <Option
                                            value={IntroductionMethod.FRIENDS}>{IntroductionMethodTitle[IntroductionMethod.FRIENDS]}</Option>
                                        <Option
                                            value={IntroductionMethod.UNIVERSITY_COMMUNITY}>{IntroductionMethodTitle[IntroductionMethod.UNIVERSITY_COMMUNITY]}</Option>
                                        <Option
                                            value={IntroductionMethod.OTHER}>{IntroductionMethodTitle[IntroductionMethod.OTHER]}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="scientificAssociationRole"
                                    label="این فیلد مخصوص اعضای انجمن‌های علمی می باشد"
                                    initialValue={user?.scientificAssociationRole}
                                >
                                    <Select allowClear>
                                        <Option
                                            value={ScientificAssociationRole.MEMBER}>{ScientificAssociationRoleTitle[ScientificAssociationRole.MEMBER] + " هستم"}</Option>
                                        <Option
                                            value={ScientificAssociationRole.SECRETARY}>{ScientificAssociationRoleTitle[ScientificAssociationRole.SECRETARY] + " هستم"}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Flex justify='end'>
                            <Form.Item>
                                <Button style={{width: "120px"}} type="primary" htmlType="submit" loading={loading}>
                                    ذخیره تغییرات
                                </Button>
                            </Form.Item>
                        </Flex>

                    </Form>
                ) : <div
                    style={{
                        height: '300px',
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spin indicator={<LoadingOutlined style={{fontSize: 48}} spin/>}/>
                </div>}
            </div>

        </Wrapper1>
    );
}
