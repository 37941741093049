import React, {useEffect, useState} from "react";
import {Alert, Button, Divider, Input, Spin, Typography} from "antd";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR, THIRD_COLOR} from "../../../../constans/colors";
import {openErrorNotification, openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";
import UserService, {User} from "../../../../services/UserService";
import {ImageSliderAffiliateContainer} from "../../../slider/ImageSliderAffiliateContainer";
import {CheckCircleOutlined, LoadingOutlined} from "@ant-design/icons";

const { Text, Paragraph } = Typography;

const AffiliateLinkContainer = () => {
    const [user, setUser] = useState<User>();
    const [inputLink, setInputLink] = useState("");
    const [affiliateLink, setAffiliateLink] = useState("");

    useEffect(() => {
        loadUser();
    }, []);

    const loadUser = () => {
        UserService.getSelfProfile().then(response => {
            setUser(response);
        });
    };

    const validateAndGenerateAffiliateLink = () => {
        const host = window.location.origin;

        if (!user?.affiliateKey) {
            openErrorNotification("کلید معرفی شما یافت نشد!");
            return;
        }

        if (!inputLink) {
            setAffiliateLink(`${host}/?ref=${user.affiliateKey}`);
            return;
        }

        try {
            const url = new URL(inputLink);
            if (url.origin !== host) {
                openErrorNotification("آدرس وارد شده معتبر نیست. لطفا آدرس یکی از صفحات دانشگاهینو را وارد کنید.");
                return;
            }
            setAffiliateLink(`${url.href}?ref=${user.affiliateKey}`);
        } catch (error) {
            openErrorNotification("لطفا یک آدرس معتبر وارد کنید.");
        }
    };

    const handleCopy = () => {
        if (!affiliateLink) {
            openErrorNotification("هیچ لینکی برای کپی کردن وجود ندارد.");
            return;
        }
        navigator.clipboard.writeText(affiliateLink)
            .then(() => openSuccessNotificationByMessage("لینک معرفی با موفقیت کپی شد!"))
            .catch(() => openErrorNotification("خطا در کپی کردن لینک"));
    };

    return (
        <>
        <div className="container">
            <Wrapper1 style={{marginTop: "0px", marginBottom: '20px', padding: "20px", color: THIRD_COLOR}}>
                <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>همکاری در فروش دانشگاهینو
                </div>
                <Divider style={{margin: '15px 0px'}}/>
                <>
                    {user?.affiliateKey ? (
                        <div style={{maxWidth: 700, margin: "0 auto", padding: "20px"}}>
                            <Alert
                                message={
                                    <Paragraph
                                        style={{
                                            textAlign: "justify",
                                            whiteSpace: "pre-line",
                                            fontSize: "16px",
                                            lineHeight: "1.8"
                                        }}>
                                        برای ساخت لینک معرفی خودت، کافیه آدرس یکی از صفحات دانشگاهینو رو توی باکس زیر
                                        وارد کنی و روی دکمه <span style={{color: "#52c41a", fontWeight: "bold"}}>لینک معرفی من رو بساز</span> کلیک
                                        کنی. اینجوری لینک اختصاصی خودت
                                        رو برای همون صفحه می‌سازی. اگه صفحه خاصی مدنظرت نیست، نگران نباش! به‌صورت
                                        پیش‌فرض، لینک معرفی تو به صفحه اصلی دانشگاهینو وصل می‌شه.

                                        <br/>
                                        <span style={{color: "#722ed1", fontWeight: "bold"}}>منتظر چی هستی؟ همین الان لینک مخصوص خودت رو بساز و برای دوستات بفرست!</span>
                                        <br/>
                                        اگه نیاز به توضیحات بیشتری داری، متن زیر رو کامل بخون.
                                    </Paragraph>
                                }
                                type="info"
                                style={{marginBottom: 20}}
                            />
                            <Text strong>آدرس مورد نظر خود را وارد کنید:</Text>
                            <Input
                                value={inputLink}
                                onChange={(e) => setInputLink(e.target.value)}
                                placeholder="آدرس یکی از صفحات دانشگاهینو"
                                style={{marginBottom: 15, marginTop: 5}}
                            />
                            <Button type="primary" onClick={validateAndGenerateAffiliateLink}
                                    style={{width: "100%", marginBottom: 15}}>
                                لینک معرفی من را بساز
                            </Button>
                            {affiliateLink && (
                                <>
                                    <Text strong>لینک معرفی شما:</Text>
                                    <Input value={affiliateLink} readOnly style={{marginBottom: 15, marginTop: 5}}/>
                                    <Button type="primary" onClick={handleCopy} style={{width: "100%"}}>
                                        کپی کردن لینک معرفی
                                    </Button>
                                </>
                            )}
                        </div>
                    ) : <div
                        style={{
                            height: '300px',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Spin indicator={<LoadingOutlined style={{fontSize: 48}} spin/>}/>
                    </div>}
                </>
            </Wrapper1>
        </div>

            <div className="container" style={{marginBottom: '20px'}}>
                <Wrapper1 style={{padding: "15px 15px"}}>
                    <Paragraph
                        style={{textAlign: "justify", whiteSpace: "pre-line", fontSize: "16px", lineHeight: "1.8"}}>
                        <span style={{color: "#ff4d4f", fontWeight: "bold"}}>سلام رفقا! </span>
                        <br/>
                        <span style={{color: "#595959"}}>می‌دونیم که شرایط اقتصادی این روزا برای همه، به خصوص دانشجوها، یکم سخت شده. اما خبر خوب اینه که دانشگاهینو یه راه جدید برای کسب درآمد براتون آورده! می‌پرسید چطوری؟ با همکاری در فروش دانشگاهینو!</span>
                        <br/><br/>
                        <span style={{color: "#1890ff", fontWeight: "bold"}}>همکاری در فروش دانشگاهینو چیه؟</span>
                        <br/>
                        <span style={{color: "#595959"}}>خیلی ساده است! شما با معرفی دوره‌های آموزشی دانشگاهینو به دوستاتون و هم‌کلاسی‌هاتون، می‌تونید درآمد کسب کنید. چطوری؟</span>
                        <br/><br/>
                        <span style={{color: "#52c41a", fontWeight: "bold"}}>📌 ثبت نام و دریافت لینک اختصاصی:</span>
                        <span style={{color: "#595959"}}> اول از همه، تو سایت دانشگاهینو ثبت نام کنید. بعد از ورود به حسابتون، یه لینک اختصاصی برای همکاری در فروش دریافت می‌کنید.</span>
                        <br/><br/>
                        <span style={{color: "#faad14", fontWeight: "bold"}}>📢 اشتراک گذاری لینک:</span>
                        <span style={{color: "#595959"}}> حالا وقتشه که لینک خودتون رو به اشتراک بذارید. می‌تونید این لینک رو تو شبکه‌های اجتماعی، گروه‌های دانشجویی یا هر جای دیگه‌ای که فکر می‌کنید مخاطب داره، منتشر کنید. برای اینکه کارتون راحت‌تر بشه، دانشگاهینو یه عالمه محتوای گرافیکی جذاب برای پست و استوری اینستاگرام آماده کرده که می‌تونید دانلود کنید و بدون دردسر، لینک خودتون رو تبلیغ کنید.</span>
                        <br/><br/>
                        <span style={{color: "#eb2f96", fontWeight: "bold"}}>💰 کسب درآمد:</span>
                        <span style={{color: "#595959"}}> هر کسی که از طریق لینک شما وارد سایت دانشگاهینو بشه و تا ۳۰ روز بعدش حق اشتراک بخره، ۲۰ درصد از مبلغ پرداختیش به حساب شما واریز می‌شه. یعنی هر چی بیشتر معرفی کنید، درآمدتون هم بیشتر می‌شه!</span>
                        <br/><br/>
                        <span style={{color: "#722ed1", fontWeight: "bold"}}>🏦 دریافت وجه نقد یا خرید حق اشتراک:</span>
                        <span style={{color: "#595959"}}> هر وقت هم که خواستید، می‌تونید مبلغی که تو حسابتون جمع شده رو به صورت نقد دریافت کنید یا باهاش برای خودتون حق اشتراک دانشگاهینو بخرید و از دوره‌های آموزشی باکیفیتش استفاده کنید.</span>
                        <br/><br/>
                        <span style={{color: "#13c2c2", fontWeight: "bold"}}>چرا همکاری در فروش دانشگاهینو؟</span>
                        <br/><br/>
                        <span style={{color: "#52c41a"}}><CheckCircleOutlined/> درآمدزایی آسان:</span>
                        <span style={{color: "#595959"}}> بدون نیاز به سرمایه اولیه یا مهارت خاصی، می‌تونید درآمد کسب کنید.</span>
                        <br/>
                        <span style={{color: "#faad14"}}><CheckCircleOutlined/> انعطاف‌پذیری:</span>
                        <span style={{color: "#595959"}}> هر وقت و هر جا که خواستید، می‌تونید لینک خودتون رو به اشتراک بذارید.</span>
                        <br/>
                        <span style={{color: "#1890ff"}}><CheckCircleOutlined/> محتوای آماده:</span>
                        <span style={{color: "#595959"}}> دیگه لازم نیست وقتتون رو صرف طراحی گرافیکی کنید. دانشگاهینو همه چیز رو براتون آماده کرده.</span>
                        <br/>
                        <span style={{color: "#eb2f96"}}><CheckCircleOutlined/> آموزش باکیفیت:</span>
                        <span style={{color: "#595959"}}> با معرفی دوره‌های دانشگاهینو، به دوستاتون کمک می‌کنید که راحت تر دروس دانشگاهی رو پاس کنند.</span>
                        <br/><br/>
                        <span style={{color: "#722ed1", fontWeight: "bold"}}>پس منتظر چی هستید؟ همین الان تو دانشگاهینو ثبت نام کنید و لینک اختصاصی خودتون رو دریافت کنید و شروع به کسب درآمد کنید!</span>
                    </Paragraph>
                </Wrapper1>
            </div>
            <div className="container">
                <ImageSliderAffiliateContainer/>
            </div>
        </>
    );
};

export default AffiliateLinkContainer;
