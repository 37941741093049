import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Modal, Space, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import {numberWithCommas, numberWithCommasToman, Page, toPersianDateTime} from "../../../../services/UtilFunctions";
import UserPlanService, {UserPlan} from "../../../../services/UserPlanService";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {SearchFilterInput} from "../../../common/SearchFilterInput";
import {UserPlanStatusColor, UserPlanStatusTitle} from "../../../../constans/enums";
import {UserPlanTotalReport} from "./UserPlanTotalReport";

export const UserPlanContainer: React.FC = () => {

    const [data, setData] = useState<Page<UserPlan>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });
    const [deleteModal, deleteModalContextHolder] = Modal.useModal();

    useEffect(() => {
        load();
    }, [params]);

    const load = () => {
        setLoading(true);
        UserPlanService.getUserPlansForAdmin(params).then(page => {
            setData(page)
        }).finally(() => setLoading(false));
    }

    const columns: ColumnsType<UserPlan> = [
        {
            title: 'نام کاربری',
            dataIndex: 'username',
            width: 150,
            render: (_, { user }) => (
                <>{user && user.username}</>
            ),
        },
        {
            title: 'نام کاربر',
            dataIndex: 'userFullName',
            width: 150,
            render: (_, { user }) => (
                <>{user && user.firstName + " " + user.lastName}</>
            ),
        },
        {
            title: 'اشتراک',
            dataIndex: 'plan',
            width: 150,
            render: (_, { plan }) => (
                <>{plan && plan.title}</>
            ),
        },
        {
            title: 'درصد تخفیف',
            dataIndex: 'discountPercentage',
            width: 100,
            render: (_, { discountPercentage }) => (
                <>{discountPercentage}</>
            ),
        },
        {
            title: 'مبلغ پرداخت شده',
            dataIndex: 'price',
            width: 200,
            render: (_, { price, finalPrice }) => (
                <>{numberWithCommas(finalPrice) + " از " + numberWithCommas(price) + " تومان"}</>
            ),
        },
        {
            title: 'سهم معرف',
            dataIndex: 'refAffiliateReward',
            width: 200,
            render: (_, { refAffiliateReward }) => (
                <>{numberWithCommasToman(refAffiliateReward)}</>
            ),
        },
        {
            title: 'تاریخ شروع',
            dataIndex: 'startDate',
            width: 150,
            render: (_, { startDate }) => (
                <>{toPersianDateTime(startDate)}</>
            ),
        },
        {
            title: 'تاریخ اتمام',
            dataIndex: 'endDate',
            width: 150,
            render: (_, { endDate }) => (
                <>{toPersianDateTime(endDate)}</>
            ),
        },

        {
            title: 'وضعیت',
            dataIndex: 'status',
            width: 100,
            render: (_, { status }) => {
                return (
                    <Tag color={UserPlanStatusColor[status]}>
                        {UserPlanStatusTitle[status]}
                    </Tag>
                );
            },
        },
        {
            width: 50,
            render: (_, { id }) => (
                <>
                    <a onClick={()=>{
                        if (id){
                            deleteModal.confirm({
                                title: 'آیا می خواهید این رکورد را حذف کنید؟',
                                icon: <ExclamationCircleOutlined />,
                                okText: 'بله',
                                cancelText: 'انصراف',
                                okButtonProps:{danger:true},
                                onOk: () => {
                                    UserPlanService.delete(id).then(value => {
                                        load()
                                    })
                                }
                            })
                        }
                    }}>حذف</a>
                </>
            )
        },
    ];

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    return (
        <div style={{paddingBottom:'100px'}}>
            <Wrapper1 style={{margin: "15px", marginTop:"0px", padding: "20px"}}>
                <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>اشتراک های خریداری شده</div>
                <Divider style={{margin: '0', marginTop: '15px'}}/>
                <Space style={{marginTop: "20px"}}>
                    <SearchFilterInput sendRequest={(inputValue : string) => {setParams(prevState => {return {...prevState, searchParam:inputValue, pageNumber: 0}})}}/>
                </Space>
                <Table className="admin-course-table" scroll={{ x: 1400 }} loading={loading} pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: 10,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
                {deleteModalContextHolder}
            </Wrapper1>
            <UserPlanTotalReport/>

        </div>
    );
}