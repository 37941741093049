import React, {useEffect, useRef, useState} from "react";
import placeholder from '../../assets/images/290_163_placeholder.png';

interface Props {
    src: string;
    alt: string;
    style?: React.CSSProperties;
}

export const LazyImage = ({src, alt, style = {}}: Props): JSX.Element => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const imgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsLoaded(true);
                    observer.disconnect();
                }
            },
            {threshold: 0.1} // Load image when 10% visible
        );

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => observer.disconnect();
    }, []);

    return (
        <img
            ref={imgRef}
            style={style}
            src={isLoaded ? src : placeholder}
            alt={alt}
        />
    );
};