import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Modal, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {Page, toPersianDateTime} from "../../../../services/UtilFunctions";
import {FileType, FileTypeTitle} from "../../../../constans/enums";
import FileService, {SavedFile} from "../../../../services/FileService";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

export const FileManagementContainer: React.FC = () => {

    const [data, setData] = useState<Page<SavedFile>>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        type: FileType.TEACHER_FILES,
        sortKey: "createdDate",
        sort: "DESC"
    });
    const [show, setShow] = useState<boolean>(false);
    const [selectedDescription, setSelectedDescription] = useState<string>();
    const [deleteModal, deleteModalContextHolder] = Modal.useModal();
    const [dataLoading, setDataLoading] = useState<boolean>(false);


    useEffect(() => {
        loadData();
    }, [params]);

    const loadData = () => {
        setDataLoading(true);
        FileService.getSavedFilesForAdmin(params).then(page => {
            setData(page)
        }).finally(() => setDataLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<SavedFile> = [
        {
            title: 'نام کاربر',
            dataIndex: 'userFullName',
            width: 200,
            render: (_, {user}) => (
                <>{user && user.firstName + " " + user.lastName}</>
            ),
        },
        {
            title: 'تاریخ ارسال',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, {createdDate}) => (
                <>{toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'نوع',
            dataIndex: 'type',
            width: 100,
            render: (_, {type}) => (
                <>{type && FileTypeTitle[type]}</>
            ),
        },
        {
            title: 'نام فایل',
            dataIndex: 'fileName',
            width: 200,
        },
        {
            title: 'توضیحات',
            dataIndex: 'description',
            width: 100,
            render: (_, {description}) => (
                description && <a onClick={event => {
                    setSelectedDescription(description);
                    setShow(true);
                }}>مشاهده</a>
            ),
        },
        {
            width: 70,
            render: (_, {fileId, fileName}) => (
                fileId && fileName ?
                    <Space size="large">
                        <a href={baseNonVideoUrl + "/" + fileId + "/" + fileName}>دانلود</a>

                        <a style={{color: 'red'}} onClick={() => {
                            deleteModal.confirm({
                                title: 'آیا می خواهید این فایل را حذف کنید؟',
                                icon: <ExclamationCircleOutlined/>,
                                okText: 'بله',
                                cancelText: 'انصراف',
                                okButtonProps: {danger: true},
                                onOk: () => {
                                    FileService.delete("/" + fileId + "/" + fileName).then(value => {
                                        loadData();
                                    })
                                }
                            })
                        }}>حذف</a>
                    </Space>
                    : null
            ),
        }

    ];

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>مدیریت فایل ها</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Table className="admin-course-table" loading={dataLoading} scroll={{x: 750}} pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: params.pageSize,
                total: data?.totalElements,
                onChange: handleSelectPagination
            }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            <Modal
                title="توضیحات"
                centered
                open={show}
                onOk={() => {
                }}
                onCancel={() => {
                    setShow(false);
                }}
                footer={[]}
                bodyStyle={{maxHeight: "700px", overflow: 'auto'}}
                width={720}
            >
                <p style={{textAlign: "justify", whiteSpace: 'pre-line'}}>{selectedDescription}</p>
            </Modal>
            {deleteModalContextHolder}
        </Wrapper1>
    );
}