import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {Category} from "./CategoryService";
import {Course} from "./CourseService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface Plan {
    id ?: number;
    title: string;
    cover: any;
    coverUrl: string;
    description: string;
    price: number;
    finalPrice: number;
    discountPercentage: number;
    categoryId: number;
    days: number;
    category: Category;
    existingCoursesCounts: number;
    recordingCoursesCounts: number;

    discountPercentageWithoutCode: number;
    discountCode?:string;

    priceWithoutApplyWallet?:number;
}

class PlanService {

    private static instance: PlanService;

    private constructor() {
    }

    public static getInstance(): PlanService {
        if (!PlanService.instance) {
            PlanService.instance = new PlanService();
        }
        return PlanService.instance;
    }

    async getPlansForAdmin(params : any): Promise<Page<Plan>> {
        return await genericApiCall<Page<Plan>>(async () => {
            const response = await axios.get<Page<Plan>>(baseUrl + "/api/v1/plans", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getPlans(params : any): Promise<Page<Plan>> {
        return await genericApiCall<Page<Plan>>(async () => {
            const response = await axios.get<Page<Plan>>(baseUrl + "/public/v1/plans", {
                params
            });
            return response.data;
        });
    }

    async addPlan(input:Plan): Promise<Plan> {
        return await genericApiCall<Plan>(async () => {
            const response = await axios.post<Plan>(baseUrl + "/api/v1/plans", input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async update(id:number | undefined, input:Plan): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/plans/" + id, input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async delete(id:number | undefined): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/plans/" + id, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getPlansByCourseId(params : any, courseId ?: number): Promise<Page<Plan>> {
        return await genericApiCall<Page<Plan>>(async () => {
            const response = await axios.get<Page<Plan>>(baseUrl + "/public/v1/plans/courses/" + courseId, {
                params
            });
            return response.data;
        });
    }

    async getById(id ?: number): Promise<Plan> {
        return await genericApiCall<Plan>(async () => {
            const response = await axios.get<Plan>(baseUrl + "/public/v1/plans/" + id);
            return response.data;
        });
    }

    async getCoursesById(id ?: number): Promise<Course[]> {
        return await genericApiCall<Course[]>(async () => {
            const response = await axios.get<Course[]>(baseUrl + "/public/v1/plans/" + id + "/course");
            return response.data;
        });
    }

}

export default PlanService.getInstance();